import React, { useMemo } from 'react'

import { TextField } from '@mui/material'
import { get as lodashGet } from 'lodash'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import ReadOnly from 'components/common/inputs/ReadOnly'

import type { FormTextFieldProps } from 'components/forms/FormTextField/FormTextField.types'

import type { TextFieldProps } from '@mui/material'

const FormTextField = ({
  autoFocus,
  name,
  required,
  fullWidth,
  label,
  margin,
  type,
  disabled,
  multiline,
  helperText,
  placeholder,
  endAdornment,
  startAdornment,
  minRows,
  readOnly,
  onValueChange,
  ReadOnlyProps,
  ...props
}: FormTextFieldProps): JSX.Element => {
  const { control, getValues } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const id = `${name}-text-field`

  // prevent number input changing value on scroll and up and down arrow keys
  const numberInputProps = useMemo(
    () =>
      type === 'number'
        ? ({
            onWheel: (e) => (e.target as HTMLInputElement).blur(),
            onKeyDown: (e) => {
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault()
              }
            }
          } as TextFieldProps)
        : {},
    [type]
  )

  if (readOnly) {
    return <ReadOnly label={label} value={getValues(name)} {...ReadOnlyProps} />
  }

  return (
    <Controller
      rules={{ required: required }}
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, ref, value } }) => (
        <TextField
          {...props}
          autoFocus={autoFocus}
          onChange={(e) => {
            onChange(e)
            onValueChange?.(e.target.value)
          }}
          onBlur={onBlur}
          ref={ref}
          fullWidth={fullWidth}
          label={label}
          margin={margin}
          value={value ?? ''}
          error={isError}
          placeholder={placeholder}
          name={name}
          id={id}
          data-testid={id}
          rows={multiline ? 4 : 1}
          helperText={errorMessage.length > 0 ? errorMessage : helperText}
          type={type}
          disabled={disabled}
          required={required}
          multiline={multiline}
          minRows={minRows}
          InputProps={{
            endAdornment: endAdornment,
            startAdornment: startAdornment,
            'aria-label': `${name}-text-field`,
            ...props.InputProps
          }}
          {...numberInputProps}
        />
      )}
    />
  )
}

export default FormTextField
