import { useCallback, useMemo } from 'react'

import {
  Apartment,
  Bookmark,
  CalendarMonth,
  Dashboard,
  Description,
  Feedback,
  Groups,
  HourglassTop,
  Insights,
  Mail,
  Newspaper,
  Notifications,
  Person,
  RequestQuote,
  School,
  Settings,
  Task,
  Textsms
} from '@mui/icons-material'

import { useTranslation } from 'next-i18next'

import usePermissions from 'hooks/permissions/usePermissions'
import useStats from 'hooks/useStats'
import useChainContextStore from 'store/chainContext'
import Routes from 'types/enums/routes'

import { TranslationNamespaces } from 'types/enums/tNamespaces'
import {
  ET_AFFILIATE_URL,
  ET_FEEDBACK_URL,
  ET_HELP_CENTER_URL,
  ET_UNIVERSITY_URL
} from 'utils/constants/global'

import type { SideNavItem } from 'hooks/common/layout/sideNav/useSideNav'

const useMainSideNavItems = (): SideNavItem[] => {
  const { t } = useTranslation([TranslationNamespaces.COMMON])

  const { selectedChain, selectedOrganization } = useChainContextStore((state) => ({
    selectedChain: state.selectedChain,
    selectedOrganization: state.selectedOrganization
  }))

  const { hasRoutePermissions } = usePermissions()

  const { userStats } = useStats()

  const shouldHideItem = useCallback(
    (route: Routes) => {
      if (selectedChain || selectedOrganization) return !hasRoutePermissions(route)

      return false
    },
    [selectedChain, selectedOrganization, hasRoutePermissions]
  )

  const items = useMemo<SideNavItem[]>(
    () => [
      {
        label: t('main'),
        variant: 'header',
        defaultExpanded: true,
        subItems: [
          {
            label: t('dashboard'),
            icon: <Dashboard />,
            href: Routes.DASHBOARD,
            hidden: shouldHideItem(Routes.DASHBOARD)
          },
          {
            label: t('calendars'),
            icon: <CalendarMonth />,
            variant: 'dropdown',
            subItems: [
              {
                label: t('bookingCalendar'),
                href: Routes.BOOKING_CALENDAR,
                hidden: shouldHideItem(Routes.BOOKING_CALENDAR)
              },
              {
                label: t('eventDiary'),
                href: Routes.EVENT_DIARY,
                hidden: shouldHideItem(Routes.EVENT_DIARY)
              },
              {
                label: t('grc'),
                href: Routes.GRC,
                hidden: shouldHideItem(Routes.GRC)
              },
              {
                label: t('taskCalendar'),
                href: Routes.TASK_CALENDAR,
                hidden: shouldHideItem(Routes.TASK_CALENDAR)
              }
            ]
          },
          {
            label: t('bookings'),
            icon: <Bookmark />,
            href: Routes.BOOKINGS,
            variant: selectedChain?.id ? 'default' : 'dropdown',
            hidden: shouldHideItem(Routes.BOOKINGS),
            subItems: selectedChain?.id
              ? undefined
              : [
                  {
                    label: t('bookings'),
                    href: Routes.BOOKINGS,
                    hidden: shouldHideItem(Routes.BOOKINGS)
                  },
                  {
                    label: t('events'),
                    href: Routes.EVENTS,
                    hidden: shouldHideItem(Routes.EVENTS)
                  },
                  {
                    label: t('groups'),
                    href: Routes.GROUPS,
                    hidden: shouldHideItem(Routes.GROUPS)
                  }
                ]
          },
          {
            label: t('contacts'),
            icon: <Person />,
            href: Routes.CONTACTS,
            hidden: shouldHideItem(Routes.CONTACTS)
          },
          {
            label: t('accounts'),
            icon: <Apartment />,
            href: Routes.ACCOUNTS,
            hidden: shouldHideItem(Routes.ACCOUNTS)
          },
          {
            label: t('tasks'),
            icon: <Task />,
            href: Routes.TASKS,
            badgeCount: userStats?.overdue_task_count,
            hidden: shouldHideItem(Routes.TASKS)
          },
          {
            label: t('mail'),
            icon: <Mail />,
            href: Routes.MAIL,
            badgeCount: userStats?.unread_email_count,
            hidden: shouldHideItem(Routes.MAIL)
          },

          {
            label: t('textMessages'),
            icon: <Textsms />,
            href: Routes.TEXT_MESSAGES,
            badgeCount: userStats?.unread_incoming_text_count,
            hidden: shouldHideItem(Routes.TEXT_MESSAGES)
          },
          {
            label: t('documents'),
            icon: <Description />,
            hidden: shouldHideItem(Routes.DOCUMENTS),
            href: Routes.DOCUMENTS
          },
          {
            label: t('proposals'),
            icon: <Newspaper />,
            href: Routes.PROPOSALS,
            hidden: shouldHideItem(Routes.PROPOSALS)
          },
          {
            label: t('invoices'),
            icon: <RequestQuote />,
            variant: 'dropdown',
            hidden: shouldHideItem(Routes.INVOICES),
            subItems: [
              {
                label: t('invoices'),
                href: Routes.INVOICES,
                hidden: shouldHideItem(Routes.INVOICES)
              },
              {
                label: t('scheduledPayments'),
                href: Routes.SCHEDULED_PAYMENTS,
                hidden: shouldHideItem(Routes.SCHEDULED_PAYMENTS)
              }
            ]
          },

          {
            label: t('automations'),
            icon: <HourglassTop />,
            href: Routes.AUTOMATIONS,
            badgeCount: userStats?.active_automation_count,
            hidden: shouldHideItem(Routes.AUTOMATIONS)
          },
          {
            label: t('reports'),
            icon: <Insights />,
            href: Routes.REPORTS
          }
        ]
      },
      {
        label: t('other'),
        variant: 'header',
        defaultExpanded: true,
        subItems: [
          {
            label: t('settings'),
            icon: <Settings />,
            href: Routes.SETTINGS,
            hidden: shouldHideItem(Routes.SETTINGS)
          },
          {
            label: t('productUpdates'),
            icon: <Notifications />,
            isCannyChangeLog: true
          },
          {
            label: t('referralProgram'),
            icon: <Groups />,
            href: ET_AFFILIATE_URL,
            external: true
          },
          {
            label: t('feedback'),
            icon: <Feedback />,
            href: ET_FEEDBACK_URL,
            external: true
          },
          {
            label: t('learningCenter'),
            variant: 'dropdown',
            icon: <School />,
            subItems: [
              {
                label: t('eLearning'),
                href: ET_UNIVERSITY_URL,
                external: true
              },
              {
                label: t('helpCenter'),
                href: ET_HELP_CENTER_URL,
                external: true
              }
            ]
          }
        ]
      }
    ],
    [t, userStats, selectedChain, shouldHideItem]
  )

  return items
}

export default useMainSideNavItems
