import React from 'react'

import { Box, Button, Tooltip, useTheme } from '@mui/material'
import { bgBlur } from '@repo/theme/styles/mixins'
import Image from 'next/image'

import Iconify from 'components/iconify'

import type { MailAttachmentProps } from 'components/mail/MailAttachment/MailAttachment.types'

import { isImageFile, mapFileIcons } from 'utils/files'

const MailAttachment = ({ attachment, height = 56, width = 56 }: MailAttachmentProps) => {
  const theme = useTheme()

  const fileUrl = attachment?.file_url
  const fileName = attachment?.file_name
  const fileType = attachment?.file_name?.split('.')?.pop() || ''

  return (
    <Box
      data-testid="MailAttachment"
      position="relative"
      sx={{ ':hover': { '.overlay': { display: 'block' } } }}>
      <Tooltip title={attachment?.file_name} arrow>
        <Button
          href={fileUrl}
          download={fileName}
          target="_blank"
          sx={{ height, width, minWidth: 'unset' }}>
          {isImageFile(fileType) && (
            <img
              src={fileUrl}
              alt={attachment?.file_name}
              style={{ height, width, borderRadius: theme.shape.borderRadius }}
            />
          )}
          {!isImageFile(fileType) && (
            <Image
              src={`/icons/files/ic-${mapFileIcons(fileType)}.svg`}
              alt={attachment?.file_name}
              width={width}
              height={height}
            />
          )}
        </Button>
      </Tooltip>
      <Box
        className="overlay"
        height={height}
        width={width}
        top={0}
        left={0}
        borderRadius={theme.shape.borderRadius / 6}
        position="absolute"
        bgcolor={bgBlur({ color: theme.palette.grey[600] }).backgroundColor}
        display="none"
        sx={{ pointerEvents: 'none', opacity: 0.75 }}>
        <Iconify
          icon="solar:round-arrow-down-bold"
          height={width / 2}
          width={height / 2}
          position="absolute"
          color={theme.palette.common.white}
          top={height / 4}
          left={width / 4}
        />
      </Box>
    </Box>
  )
}

export default MailAttachment
