import React, { type ForwardedRef, forwardRef, useEffect, useMemo, useRef, useState } from 'react'

import { Search } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'

import { useDebounceValue } from 'usehooks-ts'

import type { SearchTextFieldProps } from 'components/common/inputs/SearchTextField'

import { defaultSearchLabel } from 'utils/forms'
import { DEFAULT_DEBOUNCE } from 'utils/search'

const SearchTextField = forwardRef<typeof TextField, SearchTextFieldProps>(
  (
    { id, onValueChange, initialValue, placeholder, debounceDelay, ...props }: SearchTextFieldProps,
    ref: ForwardedRef<typeof TextField>
  ): JSX.Element => {
    const [search, setSearch] = useState(initialValue || '')
    const [debouncedText] = useDebounceValue(search, debounceDelay ?? DEFAULT_DEBOUNCE)
    const mounted = useRef(false)

    useEffect(() => {
      if (!mounted.current) {
        mounted.current = true
        return
      }

      if (onValueChange && mounted.current) onValueChange(debouncedText)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedText])

    useEffect(() => {
      if (initialValue) setSearch(initialValue)
    }, [initialValue])

    const computedPlaceholder = useMemo(() => placeholder || defaultSearchLabel, [placeholder])

    return (
      <TextField
        {...props}
        inputRef={ref}
        id={id || 'search-input'}
        size={props.size || 'small'}
        type="search"
        hiddenLabel
        placeholder={computedPlaceholder}
        value={search}
        fullWidth
        onChange={(e) => setSearch(e.target.value)}
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: (theme) => theme.shape.borderRadius / 6
        }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            ...props?.slotProps?.input
          }
        }}
      />
    )
  }
)

SearchTextField.displayName = 'SearchTextField'

export default SearchTextField
