import React, { type ForwardedRef, forwardRef } from 'react'

import { Box } from '@mui/material'
import clsx from 'clsx'
import { MuiColorInput } from 'mui-color-input'

import type { ColorTextFieldProps } from 'components/common/inputs/ColorTextField'

import ReadOnly from 'components/common/inputs/ReadOnly'

import styles from 'styles/ColorTextField.module.scss'

type MuiColorComponent = typeof MuiColorInput

/**
 * Renders a color text field component built on top of mui-color-input.
 * @param {ColorTextFieldProps} props - The props of the component.
 * @param {ForwardedRef<MuiColorComponent>} ref - The ref to the component.
 * @returns {React.ReactElement} The color text field component.
 */
const ColorTextField = forwardRef<MuiColorComponent, ColorTextFieldProps>(
  (props: ColorTextFieldProps, ref: ForwardedRef<MuiColorComponent>) => {
    if (props.readOnly) {
      return (
        <ReadOnly label={props.label} value={props.value}>
          <Box
            role="color-box"
            bgcolor={props.value}
            height="1.5rem"
            width="1.5rem"
            mr=".5rem"
            borderRadius="15%"
            border={`1px solid ${props.value ? 'transparent' : 'black'}`}
          />
        </ReadOnly>
      )
    }

    return (
      <MuiColorInput
        inputRef={ref}
        format="hex"
        value={props.value || ''}
        className={clsx(styles.input, props?.className)}
        InputProps={{ ...props.InputProps }}
        {...props}
      />
    )
  }
)

ColorTextField.displayName = 'ColorTextField'

export default ColorTextField
