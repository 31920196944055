import dynamic from 'next/dynamic'

import { ErrorBoundary } from '@sentry/nextjs'

import ErrorFallback from 'components/common/ErrorBoundary/ErrorFallback'
import useErrorBoundary from 'hooks/useErrorBoundary'

import BaseProviders from 'providers/BaseProviders'

import type { ReactNode } from 'react'

interface ErrorFallbackProps {
  resetError: () => void
}

const BaseLayout = ({ children, ...pageProps }: { children: ReactNode }) => {
  const { appBoundaryRef } = useErrorBoundary()

  return (
    <BaseProviders {...pageProps}>
      <ErrorBoundary
        fallback={({ resetError }: ErrorFallbackProps) => {
          appBoundaryRef.current = resetError

          return <ErrorFallback resetError={resetError} />
        }}>
        {children}
      </ErrorBoundary>
    </BaseProviders>
  )
}

export default dynamic(() => Promise.resolve(BaseLayout), { ssr: false })
