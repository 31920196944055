import type { ForwardedRef, HTMLAttributes, SyntheticEvent } from 'react'
import React, { forwardRef, useEffect, useState } from 'react'

import { ListItem, Autocomplete as MuiAutocomplete } from '@mui/material'

import { useDebounceValue } from 'usehooks-ts'

import type { AutocompleteProps, AutocompleteValue } from 'components/common/inputs/Autocomplete'

import AutocompleteInput from 'components/common/inputs/Autocomplete/AutocompleteInput'
import ReadOnly from 'components/common/inputs/ReadOnly'

import { useDidMount } from 'hooks/useDidMount'

import { DEFAULT_DEBOUNCE } from 'utils/search'

import type {
  AutocompleteOwnerState,
  AutocompleteRenderOptionState,
  FilterOptionsState
} from '@mui/material'

import type { ValueLabelPair } from '@repo/et-types'

type MuiAutocompleteComponent = typeof MuiAutocomplete

const defaultRole = 'comboBox'
const newId = 'new'

const defaultNone = { label: 'None', value: null }

const Autocomplete = forwardRef<MuiAutocompleteComponent, AutocompleteProps>(
  (
    {
      AutocompleteInputProps,
      canCreateNew,
      createNewMessage,
      debounceDelay,
      defaultValue,
      errorMessage,
      helperText,
      inputRef,
      isError,
      isLoading,
      label,
      multiple,
      name,
      noValue = false,
      onBlur,
      onChange,
      onCreateNew,
      onInputChange,
      onSearchChange,
      placeholder,
      readOnly,
      readOnlyInput,
      ReadOnlyProps,
      renderOption,
      required,
      shouldShowNone,
      value,
      values,
      ...props
    }: AutocompleteProps,
    ref: ForwardedRef<MuiAutocompleteComponent>
  ) => {
    const [options, setOptions] = useState<ValueLabelPair[]>([])
    const [searchTerm, setSearchTerm] = useState<string>('')
    const didMount = useDidMount()
    const [debouncedSearchTerm] = useDebounceValue(searchTerm, debounceDelay ?? DEFAULT_DEBOUNCE)
    const [autocompleteValue, setAutoCompleteValue] = useState<AutocompleteValue>(
      defaultValue || (multiple ? [] : null)
    )

    const id = `${name}-autocomplete`

    const handleOptionRender = (
      props: HTMLAttributes<HTMLLIElement> & { key: number },
      option: ValueLabelPair,
      state: AutocompleteRenderOptionState,
      ownerState: AutocompleteOwnerState<ValueLabelPair, boolean, boolean, boolean>
    ) => {
      const index: number = props['data-option-index']
      const finalProps = { ...props, 'data-testid': `${id}-option-${index}` }

      return (
        <ListItem {...finalProps} key={index}>
          {renderOption ? renderOption(props, option, state, ownerState) : option.label}
        </ListItem>
      )
    }

    const isOptionEqualToValue = (
      option: ValueLabelPair,
      currentValue?: ValueLabelPair | ValueLabelPair[]
    ): boolean => {
      if (Array.isArray(currentValue)) {
        return currentValue.some((value) => value?.value === option.value)
      }

      return String(option?.value) === String(currentValue?.value)
    }

    const getOptionLabel = (option?: ValueLabelPair | string) => {
      if (option) {
        if (typeof option === 'string') return option
        else if (option?.label) return option.label
      }

      return ''
    }

    const getOptionDisabled = (option: ValueLabelPair) =>
      option && 'disabled' in option && typeof option.disabled !== 'undefined'
        ? option.disabled
        : false

    const handleOnChange = (_: SyntheticEvent, data: ValueLabelPair | ValueLabelPair[]) => {
      if (!Array.isArray(data) && data?.value === newId) {
        data.label = data.inputValue
        if (onCreateNew) onCreateNew(data)
      }

      const newValue =
        multiple && Array.isArray(data)
          ? data.map((item: ValueLabelPair) => item?.value)
          : (data as ValueLabelPair)?.value

      if (!noValue) setAutoCompleteValue(data)

      return onChange(newValue ?? null)
    }

    const handleFilterOptions = (
      options: ValueLabelPair[],
      state: FilterOptionsState<ValueLabelPair>
    ): ValueLabelPair[] => {
      const inputValue = state.inputValue
      const finalOptions = [...options]

      if (canCreateNew && inputValue.length > 0) {
        finalOptions.unshift({
          label: `${createNewMessage || 'Create:'} ${inputValue}`,
          inputValue: inputValue,
          value: newId
        })
      }

      return finalOptions
    }

    useEffect(() => {
      if (values) {
        const singleOptions = () => {
          const finalOptions = [...values]

          if (
            defaultValue &&
            !Array.isArray(defaultValue) &&
            !finalOptions.find((op) => op.value === (defaultValue as ValueLabelPair)?.value)
          ) {
            finalOptions.unshift(defaultValue as ValueLabelPair)
          }

          if (shouldShowNone) finalOptions.unshift(defaultNone)

          return finalOptions
        }

        const multipleOptions = () => {
          const finalOptions = [...values]

          if (defaultValue && Array.isArray(defaultValue)) {
            defaultValue.forEach((item) => {
              if (
                !finalOptions.find((op) => op.value === (item as ValueLabelPair)?.value) &&
                item
              ) {
                finalOptions.unshift(item as ValueLabelPair)
              }
            })
          }

          if (shouldShowNone) finalOptions.unshift(defaultNone)

          return finalOptions
        }

        if (!multiple) setOptions(singleOptions())
        else setOptions(multipleOptions())
      }
    }, [values, defaultValue, multiple, shouldShowNone])

    useEffect(() => {
      if (!didMount) return

      if (onSearchChange) onSearchChange(searchTerm || '')

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm])

    // Handle when the value changes from outside the component
    useEffect(() => {
      if (!didMount) return

      if (!value) setAutoCompleteValue(multiple ? [] : null)
      else if (value !== autocompleteValue) {
        if (multiple) {
          const newValue = (value as string[]).map((val) => {
            const option = options.find((op) => op.value === val)

            if (option) return option
            else return { value: val, label: val }
          })

          setAutoCompleteValue(newValue)
        } else {
          const option = options.find((op) => op.value === value)

          if (option) setAutoCompleteValue(option)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    if (readOnly) {
      const readOnlyValue = Array.isArray(autocompleteValue)
        ? autocompleteValue?.map((val) => {
            if (typeof val === 'string' || typeof val === 'number') return val
            else if (val?.label) return val.label
          })
        : (autocompleteValue as ValueLabelPair)?.label

      return <ReadOnly label={label} value={readOnlyValue} {...ReadOnlyProps} />
    }

    return (
      <MuiAutocomplete
        // @ts-expect-error - The autocomplete components seems to not resolve the
        // value type properly.
        value={autocompleteValue}
        id={id}
        role={defaultRole}
        ref={ref}
        options={options}
        filterOptions={handleFilterOptions}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={handleOptionRender}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        // @ts-expect-error - The autocomplete components seems to not resolve the
        // value type properly.
        onChange={handleOnChange}
        multiple={multiple}
        selectOnFocus
        disableCloseOnSelect={multiple}
        onInputChange={(e, newInputValue, reason) => {
          onInputChange?.(e, newInputValue, reason)

          // selecting a value from the list also triggers the input change event
          // we need to ignore this event
          if (reason === 'input' || reason === 'clear') {
            setSearchTerm(newInputValue)
          }
        }}
        renderInput={(params) => {
          const inputValue = params.inputProps?.value || ''
          const shouldUseNoneAsInputValue =
            shouldShowNone && !inputValue && (Array.isArray(value) ? value?.length === 0 : !value)

          return (
            <AutocompleteInput
              {...AutocompleteInputProps}
              params={{
                ...params,
                inputProps: {
                  ...params.inputProps,
                  readOnly: readOnlyInput,
                  value: shouldUseNoneAsInputValue ? defaultNone.label : inputValue
                }
              }}
              error={isError}
              helperText={errorMessage?.length > 1 ? errorMessage : helperText}
              name={name}
              label={label}
              isLoading={isLoading}
              placeholder={placeholder || params.inputProps?.placeholder}
              onBlur={onBlur}
              ref={inputRef}
              required={required}
              autocompleteValue={value as string | string[]}
              canCreateNew={canCreateNew}
            />
          )
        }}
        {...props}
      />
    )
  }
)

Autocomplete.displayName = 'Autocomplete'

export default Autocomplete
