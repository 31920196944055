import React from 'react'

import _ from 'lodash'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import Select from 'components/common/inputs/Select'
import type { FormSelectProps } from 'components/forms/FormSelect/FormSelect.types'

const FormSelect = ({ name, values, required, ...props }: FormSelectProps): JSX.Element => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && _.get(errors, name)) {
    isError = true
    errorMessage = _.get(errors, name)?.message as string
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field: { onChange, onBlur, ref, value } }) => (
        <Select
          {...props}
          error={isError}
          errorMessage={errorMessage}
          onChange={onChange}
          onBlur={onBlur}
          onClose={onBlur}
          required={required}
          ref={ref}
          value={value}
          values={values}
          name={name}
        />
      )}
    />
  )
}

export default FormSelect
