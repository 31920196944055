import { useMemo } from 'react'

import { Circle } from '@mui/icons-material'

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme
} from '@mui/material'

import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'

import { CannyChangelog } from 'react-canny'

import SideNavBadge from 'components/layout/SideNav/SideNavBadge'
import Routes from 'types/enums/routes'
import { getBasePath } from 'utils/helpers'

import type { SideNavItem as SideNavItemProps } from 'hooks/common/layout/sideNav/useSideNav'
import type { KeyboardEventHandler } from 'react'

const linkStyles = {
  textDecoration: 'none',
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer'
}

const SideNavLink = ({
  badgeCount = 0,
  component,
  external,
  href,
  icon,
  inCollapse,
  isCannyChangeLog,
  label,
  mini,
  onClick
}: { mini?: boolean; inCollapse?: boolean } & SideNavItemProps) => {
  const pathname = usePathname()
  const router = useRouter()
  const theme = useTheme()

  const isActive = useMemo(() => {
    if (href === Routes.ROOT) return pathname === Routes.ROOT // Special case for root href

    const basePathname = getBasePath(pathname as Routes)
    const activePath = Boolean(href && basePathname?.startsWith(href))

    return activePath
  }, [href, pathname])

  if (component) return component

  const iconStyles = {
    color: theme.palette.common.white,
    marginRight: mini ? 0 : 2,
    // When mini, scale the icon to 3x its size so we counter the scale of the
    // side nav. This prevents the icon from looking stretched.
    transform: mini ? 'scaleX(3)' : 'none'
  }

  const buttonStyles = {
    borderRadius: 1,
    justifyContent: mini ? 'center' : 'initial'
  }

  // The normal way users will navigate is by clicking on the link.
  // However, if tabbing through the links, we need to listen for the
  // enter key on the button to navigate.
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      if (href) {
        if (external) return window.open(href, '_blank')

        return router.push(href)
      }
    }
  }

  const showTooltip = inCollapse || !mini

  const renderButton = () => (
    <ListItemButton
      data-canny-changelog={isCannyChangeLog}
      data-nav-mini={mini}
      selected={isActive}
      sx={buttonStyles}
      onKeyDown={handleKeyDown}
      onClick={onClick}>
      {icon && <ListItemIcon sx={iconStyles}>{icon}</ListItemIcon>}
      {inCollapse && (
        <ListItemIcon>
          <Circle sx={{ height: '8px', width: '8px' }} />
        </ListItemIcon>
      )}
      {!mini && (
        <ListItemText
          secondary={label}
          secondaryTypographyProps={{
            color: isActive ? theme.palette.primary.light : 'inherit',
            fontWeight: 'semiBold'
          }}
        />
      )}
      <SideNavBadge badgeCount={badgeCount} variant={mini ? 'dot' : 'default'} />
    </ListItemButton>
  )

  const renderLinkButton = () => (
    <Link href={href || ''} target={external ? '_blank' : undefined} style={linkStyles}>
      {renderButton()}
    </Link>
  )

  return (
    <>
      <Tooltip title={showTooltip ? undefined : label} placement="right" arrow>
        <ListItem disableGutters disablePadding>
          {href ? renderLinkButton() : renderButton()}
        </ListItem>
      </Tooltip>
      {isCannyChangeLog && (
        <CannyChangelog position="right" align="bottom" component={() => null} />
      )}
    </>
  )
}

export default SideNavLink
