export enum TranslationNamespaces {
  // main app namespaces
  ACCOUNTS = 'features/accounts',
  ACTIVITIES = 'features/activities',
  ATTACHMENTS = 'features/attachments',
  BOOKINGS = 'features/bookings',
  BOOKING_MEMBERS = 'features/bookingMembers',
  BOOKING_SCHEDULED_PAYMENTS = 'features/bookingScheduledPayments',
  CALENDARS = 'features/calendars',
  CONTACTS = 'features/contacts',
  DASHBOARD = 'features/dashboard',
  DOCUMENTS = 'features/documents',
  EVENTS = 'features/events',
  EXPORTS = 'features/exports',
  GROUPS = 'features/groups',
  INVOICES = 'features/invoices',
  LINE_ITEMS = 'features/lineItems',
  NOTES = 'features/notes',
  PAYMENTS = 'features/payments',
  PMS_RECORDS = 'features/pmsRecords',
  PMS = 'features/pms',
  PROPOSALS = 'features/proposals',
  QUOTE_ITEMS = 'features/quoteItems',
  REPORTS = 'features/reports',
  SCHEDULED_PAYMENTS = 'features/scheduledPayments',
  TASKS = 'features/tasks',
  VIEWS = 'features/views',

  // settings namespaces
  CUSTOM_FIELDS = 'features/settings/customFields',
  CUSTOM_FIELD_SETS = 'features/settings/customFieldSets',
  DOCUMENT_TEMPLATES = 'features/settings/documentTemplates',
  EMAIL_SETTINGS = 'features/settings/emails',
  GUEST_PORTAL = 'features/settings/guestPortal',
  INTAKE_FORMS = 'features/settings/intakeForms',
  MENUS = 'features/settings/menus',
  MENU_ITEMS = 'features/settings/menuItems',
  MENU_CATEGORIES = 'features/settings/menuCategories',
  OAUTH_APPLICATIONS = 'features/settings/oauthApplications',
  ORGANIZATION_OVERVIEW = 'features/settings/organizationOverview',
  ORGANIZATION_PREFERENCES = 'features/settings/organizationPreferences',
  PERSONAL_SETTINGS = 'features/settings/personalSettings',
  PROPOSAL_TEMPLATES = 'features/settings/proposalTemplates',
  PROPOSAL_ELEMENTS = 'features/settings/proposalElements',
  ROOM_TYPES = 'features/settings/roomTypes',
  SETUP_VALUES = 'features/settings/setupValues',
  SPACES = 'features/settings/spaces',
  SPACE_CLOSURES = 'features/settings/spaceClosures',
  STORE_FRONTS = 'features/settings/storeFronts',
  TEMPLATES = 'features/settings/templates',
  USERS = 'features/settings/users',
  WORKFLOW_ACTIONS = 'features/settings/workflowActions',

  // others
  COMMON = 'common',
  DIALOG = 'dialog',
  SNACKBAR = 'snackbar',
  ACTIONS = 'actions',
  FORMS = 'forms',
  NAVIGATION = 'navigation'
}
