import { HTTPMethod } from '@repo/et-types/common/api'

import useResourceMethods from 'hooks/useResourceMethods'
import api from 'utils/api'
import { multiPartFileUpload } from 'utils/fileUploadUtils'

import type { CurrentUser } from '@repo/et-types'
import type { LogoUploadParams, LogoUploadResponse } from '@repo/et-types/common/api'
import type { AxiosResponse } from 'axios'

import type { PasswordFormValues } from 'components/users/UserPasswordForm'
import type { UseResource } from 'types/resources'

export const CURRENT_USER_PATH_NAME = '/v2/current_user'
export const CURRENT_USER_RESOURCE_NAME = 'current_user'

export const currentUserDefaultInclude: Array<string> = [
  'chain', // Load the current user's chain (used for permissions)
  'chain_organizations.organization_setting', // Load the current user's chain organizations (used for permissions) and their organization settings
  'chain_organizations.subscription', // Load the current user's chain organizations (used for permissions) and their subscriptions
  'chain_features.feature', // Load the current user's chain features (used for permissions)
  'organization_memberships.role.role_permissions', // Load the current user's organization memberships and their roles and permissions (used for permissions)
  'organization_features.feature', // Load the current user's organization features (used for permissions)
  'user_setting', // Load the current user's user settings (used for user preferences ie. toggled sidebar, etc.)
  'email_setting' // Load the current user's email settings (used for email provider)
]

export type UserStats = {
  overdue_task_count: number
  active_automation_count: number
  unread_incoming_text_count: number
  unread_email_count: number
}

export type UserStatsResponse = {
  stats: UserStats
}

interface UseCurrentUserResource extends UseResource<CurrentUser> {
  getStats: (params: {
    organization_id: number
  }) => Promise<AxiosResponse<UserStatsResponse, unknown>>
  uploadLogo: (params: LogoUploadParams) => Promise<AxiosResponse<LogoUploadResponse, unknown>>
  updatePassword: (params: PasswordFormValues) => Promise<AxiosResponse<unknown, unknown>>
}

const useCurrentUserResource = (): UseCurrentUserResource => {
  const methods = useResourceMethods<CurrentUser>({
    path: CURRENT_USER_PATH_NAME,
    name: CURRENT_USER_RESOURCE_NAME
  })

  const uploadLogo = async ({ file, onUploadProgress }: LogoUploadParams) =>
    multiPartFileUpload<LogoUploadResponse>({
      url: `${CURRENT_USER_PATH_NAME}/logo_upload`,
      file: file,
      onUploadProgress: onUploadProgress
    })

  const updatePassword = async ({
    password,
    current_password,
    password_confirmation
  }: PasswordFormValues) =>
    api.request({
      method: HTTPMethod.POST,
      url: `${CURRENT_USER_PATH_NAME}/update_password`,
      data: { password, current_password, password_confirmation }
    })

  const getStats = async ({ organization_id }: { organization_id: number }) =>
    api.get(`${CURRENT_USER_PATH_NAME}/stats?organization_id=${organization_id}`)

  return {
    ...methods,
    defaultInclude: currentUserDefaultInclude,
    getStats,
    pathName: CURRENT_USER_PATH_NAME,
    queryKey: ['currentUser'],
    resourceName: CURRENT_USER_RESOURCE_NAME,
    toFormData: () => ({}),
    uploadLogo,
    updatePassword
  }
}

export default useCurrentUserResource
