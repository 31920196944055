import { useCallback, useEffect, useState } from 'react'

import useChainContextStore from 'store/chainContext'
import useCurrentUserStore from 'store/currentUser'

import type { Locale } from 'date-fns'

const DEFAULT_LOCALE = 'en-US'

const useDateFnsLocale = (): Locale | undefined => {
  const [locale, setLocale] = useState<Locale | undefined>(undefined)

  const orgLocale = useChainContextStore((state) => state.selectedOrganization?.locale)
  const fallbackLocale = useCurrentUserStore((state) => state.organizations?.[0]?.locale) // for chain context
  const localeString = orgLocale ?? fallbackLocale ?? DEFAULT_LOCALE

  const getLocale = useCallback(async (localeName: string) => {
    try {
      const getLocale = await import(`date-fns/locale/${localeName}.mjs`)

      const locale: Locale = getLocale.default

      setLocale(locale)
    } catch {
      // some locales are not supported by date-fns
      // for example, 'hi-IN' is not supported. So, we need to handle this case and fallback to 'hi'
      if (localeName.includes('-')) {
        const [language] = localeName.split('-')

        return getLocale(language)
      }

      return undefined
    }
  }, [])

  useEffect(() => {
    getLocale(localeString)
  }, [getLocale, localeString])

  return locale
}

export default useDateFnsLocale
