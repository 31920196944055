import React from 'react'

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

import type { DragDropContextProps } from 'components/dnd/DragDropContext/DragDropContext.types'

const DragDropContext = ({ children, ...props }: DragDropContextProps) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 8 }
  })
  const touchSensor = useSensor(TouchSensor, {})
  const keyboardSensor = useSensor(KeyboardSensor, {})
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor)

  return (
    <DndContext sensors={sensors} modifiers={[restrictToFirstScrollableAncestor]} {...props}>
      {children}
    </DndContext>
  )
}

export default DragDropContext
