import React from 'react'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import type { RelativeDatePickerProps } from 'components/common/date/RelativeDatePicker/RelativeDatePicker.types'

import { dateOptionLabels } from 'utils/dateOptionLabels'

import type { RelativeDate } from 'types/dates'

const RelativeDatePicker = ({
  label,
  relativeDate,
  onRelativeDateChange,
  options = dateOptionLabels,
  SelectProps
}: RelativeDatePickerProps): JSX.Element => (
  <Select
    {...SelectProps}
    label={label}
    onChange={(event) => onRelativeDateChange?.(event.target.value as RelativeDate)}
    value={relativeDate ?? ''}>
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </Select>
)

export default RelativeDatePicker
