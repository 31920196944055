import { useRef } from 'react'

import { Menu } from '@mui/icons-material'
import { AppBar, Grid, IconButton, Toolbar, useTheme } from '@mui/material'

import { bgBlur } from '@repo/theme/styles/mixins'
import { useToggle } from 'usehooks-ts'

import GlobalSearch from 'components/common/GlobalSearch/GlobalSearch'
import Slot from 'components/common/Slot'
import BackToClassicButton from 'components/layout/TopNav/BackToClassicButton'
import EmailErrorPopover from 'components/layout/TopNav/EmailErrorPopover'
import TopNavProfileButton from 'components/layout/TopNav/TopNavProfileButton'

import GlobalContextPicker from 'components/pickers/GlobalContextPicker'
import UserProfileMenu from 'components/users/UserProfileMenu'
import useResponsive from 'hooks/useResponsive'

import useCurrentUserStore from 'store/currentUser'
import { SIDE_NAV, TOP_NAV } from 'utils/constants/layout'

type TopNavProps = {
  toggleSideNav: () => void
  sideNavMini?: boolean
}

const TopNav = ({ toggleSideNav, sideNavMini }: TopNavProps) => {
  const lgUp = useResponsive('up', 'lg')
  const theme = useTheme()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const [userMenuOpen, toggleUserMenu] = useToggle(false)
  const [, toggleSearch] = useToggle(false)

  const [chainAdmin, chain, emailSettings, currentUserOrganizations] = useCurrentUserStore(
    (state) => [state.chain_admin, state.chain, state.email_setting, state.organizations]
  )

  const showGlobalContextPicker =
    chainAdmin || (currentUserOrganizations.length > 0 && (chain?.organizations_count ?? 0) > 1)

  const appBarStyles = {
    height: TOP_NAV.H_DESKTOP,
    top: 0,
    transform: lgUp
      ? `translateX(${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px)`
      : 'none',
    ...bgBlur({ color: theme.palette.background.paper })
  }

  const toolBarStyles = {
    height: TOP_NAV.H_DESKTOP,
    width: lgUp ? `calc(100% - ${sideNavMini ? SIDE_NAV.W_MINI : SIDE_NAV.W_VERTICAL}px)` : '100%'
  }

  const emailProviderError = emailSettings?.errored || emailSettings?.error_message

  return (
    <>
      <AppBar position="fixed" sx={appBarStyles}>
        <Toolbar sx={toolBarStyles}>
          <Grid container alignItems="center" flexWrap="nowrap" columnSpacing={4}>
            {!lgUp && (
              <Grid item>
                <IconButton onClick={toggleSideNav}>
                  <Menu />
                </IconButton>
              </Grid>
            )}
            {/*
              Using display here because the top-nav-page-title element always needs to be present in dom.
              This is because the PageTitle component uses createPortal to render the title in the top nav.
            */}
            <Grid item flex=".5" display={{ xs: 'none', lg: 'block' }}>
              <div id="top-nav-page-title" />
            </Grid>
            <Grid item flex="1" display="flex" flexWrap="nowrap">
              <Grid
                container
                flexWrap="nowrap"
                columnSpacing={2}
                flex="1"
                display="flex"
                alignItems="center"
                justifyContent="flex-end">
                <Grid item flex="1" maxWidth={lgUp ? '20rem' : 'unset'}>
                  <GlobalSearch toggleSearch={toggleSearch} />
                </Grid>
                {lgUp && (
                  <Grid item>
                    <BackToClassicButton />
                  </Grid>
                )}
                {emailProviderError && (
                  <Grid item>
                    <EmailErrorPopover emailSettings={emailSettings} />
                  </Grid>
                )}
                <Grid item>
                  <TopNavProfileButton
                    buttonRef={buttonRef}
                    onClick={toggleUserMenu}
                    open={userMenuOpen}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <UserProfileMenu open={userMenuOpen} doClose={toggleUserMenu} anchorEl={buttonRef.current}>
        {showGlobalContextPicker && (
          <Slot name="global-context-picker">
            <GlobalContextPicker onSelect={toggleUserMenu} />
          </Slot>
        )}
      </UserProfileMenu>
    </>
  )
}

export default TopNav
