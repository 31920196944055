import React, { useState } from 'react'
import type { MouseEvent, SyntheticEvent } from 'react'

import { Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  Typography
} from '@mui/material'

import type { EditableFormProps } from 'components/forms/EditableForm/EditableForm.types'

import FormBase from 'components/forms/FormBase/FormBase'

import { defaultDiscardLabel, defaultEditLabel, defaultSubmitLabel } from 'utils/forms'

import type { FieldValues } from 'react-hook-form'

const EditableForm = <T extends FieldValues>({
  AccordionProps,
  AccordionSummaryProps,
  AccordionDetailsProps,
  CardProps,
  children,
  customActions,
  discardButtonLabel,
  discardButtonProps,
  editable = true,
  expandable = false,
  editButtonLabel,
  editButtonProps,
  editButton,
  editMode,
  isLoading,
  onDiscard,
  onEdit,
  onSubmit,
  submitButtonLabel,
  submitButtonProps,
  subTitle,
  methods,
  title,
  titleCollapseIcon,
  titleExpandIcon,
  formProps,
  showTitleIcon
}: EditableFormProps<T>) => {
  const [expanded, setExpanded] = useState<boolean>(AccordionProps?.defaultExpanded ?? false)

  const handleButtonClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    callback: () => void
  ) => {
    e.stopPropagation()

    callback()
  }

  const handleExpandedChange = (_: SyntheticEvent, expanded: boolean) => setExpanded(expanded)

  const defaultAccordionSummaryProps = { expandIcon: null }

  return (
    <Card {...CardProps}>
      <FormBase methods={methods} formProps={formProps}>
        <Accordion
          {...AccordionProps}
          expanded={AccordionProps?.expanded ?? (expandable ? expanded : true)}
          onChange={handleExpandedChange}>
          <AccordionSummary
            {...defaultAccordionSummaryProps}
            {...AccordionSummaryProps}
            sx={{ flexDirection: AccordionSummaryProps?.expandIcon ? 'row-reverse' : 'row' }}>
            <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
              <Grid item>
                {typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}
                {typeof subTitle === 'string' ? (
                  <Typography variant="body1">{subTitle}</Typography>
                ) : (
                  subTitle
                )}
              </Grid>
              {showTitleIcon && (
                <Grid item flex={1} display="flex">
                  {(AccordionProps?.expanded ?? (expandable ? expanded : true))
                    ? titleCollapseIcon
                    : titleExpandIcon}
                </Grid>
              )}
              <Grid item>
                {editable && (
                  <>
                    {editMode && (
                      <>
                        <Button
                          color="error"
                          variant="text"
                          onClick={(e) => handleButtonClick(e, onDiscard)}
                          size="small"
                          {...discardButtonProps}>
                          {discardButtonLabel || defaultDiscardLabel}
                        </Button>

                        {onSubmit && (
                          <LoadingButton
                            onClick={(e) => handleButtonClick(e, onSubmit)}
                            variant="text"
                            size="small"
                            loading={isLoading}
                            data-testid={`editable-card-submit-${title}`}
                            {...submitButtonProps}>
                            {submitButtonLabel || defaultSubmitLabel}
                          </LoadingButton>
                        )}
                      </>
                    )}
                    {!editMode &&
                      (editButton || (
                        <Button
                          color="primary"
                          startIcon={<Edit />}
                          onClick={(e) => handleButtonClick(e, onEdit)}
                          size="small"
                          data-testid={`editable-card-edit-${title}`}
                          {...editButtonProps}>
                          {editButtonLabel || defaultEditLabel}
                        </Button>
                      ))}
                  </>
                )}
                {customActions}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails {...AccordionDetailsProps}>{children}</AccordionDetails>
        </Accordion>
      </FormBase>
    </Card>
  )
}

export default EditableForm
