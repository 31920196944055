export enum DateFormat {
  d = 'd', // eg: 1, 2,
  dd = 'dd', // eg: 01, 02
  MMMM = 'MMMM', // eg: January, February
  yyyy = 'yyyy', // eg: 2020, 2011
  MMMM_do_YYYY = 'MMMM do, yyyy', // eg: January 1st, 2020, February 2nd, 2011
  MMM_d_YYYY = 'MMM d, yyyy', // eg: Jan 01, 2020, Feb 02 2011
  dd_MMMM_yyyy = 'dd MMMM yyyy', // eg: 01 January 2020, 02 February 2011
  EEEE_MMMM_d_yyyy = 'EEEE, MMMM d, yyyy', // eg: Friday, January 1, 2020, Wednesday, February 2, 2011
  EEE_MMM_d_yyyy = 'EEE, MMM d, yyyy', // eg: Fri, Jan 1, 2020, Wed, Feb 2, 2011
  yyyyMMdd = 'yyyy/MM/dd', // eg: 2020/01/01, 2011/02/02
  yyyy_MM_dd = 'yyyy-MM-dd', // eg: 2020-01-01, 2011-02-02
  MMMM_yyyy = 'MMMM yyyy' // eg: January 2020, February 2011
}

export enum TimeFormat {
  a = 'a', // eg: AM, PM
  hh = 'hh', // eg: 01, 02 -> 12 hour clock
  HH = 'HH', // eg: 01, 02 -> 24 hour clock
  mm = 'mm', // eg: 00, 30, 59
  h_mm_ss_a = 'h:mm:ss a', // eg: 12:00:00 AM, 8:30:00 PM -> 12 hour clock
  H_mm_ss = 'H:mm:ss', // eg: 0:00:00, 20:30:00, 23:59:59 -> 24 hour clock
  h_mm_a = 'h:mm a' // eg: 12:00 AM, 8:30 PM -> 12 hour clock
}

export enum DateTimeFormat {
  EEE_MMM_d_yyyy_h_mm_a = 'EEE, MMM d, yyyy, h:mm a', // eg: Fri, Jan 1, 2020, 12:00 AM -> 12 hour clock
  EEE_MMM_d_yyyy_H_mm = 'EEE, MMM d, yyyy, H:mm', // eg: Fri, Jan 1, 2020, 20:30 -> 24 hour clock
  H_mm = 'h:mm a', // eg: 0:00:00, 20:30:00, 23:59:59 -> 12 hour clock
  MMM_d_yyyy_h_mm_a = 'MMM d, yyyy, h:mm a', // eg: Jan 1, 2020, 12:00 AM -> 12 hour clock
  MMM_d_yyyy_H_mm = 'MMM d, yyyy, H:mm', // eg: Jan 1, 2020, 20:30 -> 24 hour clock
  MMM_d_yyyy_h_mm_a_zz = 'MMM d, yyyy h:mm a zz', // eg: Feb 7, 2021 12:00 PM PST -> 12 hour clock
  MMM_d_yyyy_H_mm_zz = 'MMM d, yyyy H:mm zz', // eg: Feb 7, 2021 20:30 PST -> 24 hour clock
  MMM_d_yyyy = 'MMM d, yyyy', // eg: Jan 1, 2020
  MM_dd_yyyy_h_mm_a_zzz = 'MM-dd-yyyy h:mm a zz', // eg: 05-23-2024 02:38PM EDT
  MM_dd_yyyy_h_mm_a_zz = 'MM-dd-yyyy h:mm a zz'
}
