import type { ChangeEvent } from 'react'
import React from 'react'

import { Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material'
import clsx from 'clsx'
import { get as lodashGet } from 'lodash'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import ReadOnly from 'components/common/inputs/ReadOnly'
import FormLabel from 'components/forms/FormLabel'
import type { FormSwitchProps } from 'components/forms/FormSwitch/FormSwitch.types'

import styles from 'styles/FormSwitch.module.scss'

const FormSwitch = ({
  label,
  labelPlacement,
  name,
  required,
  disabled,
  type = 'switch',
  helperText,
  infoText,
  readOnly,
  onChange,
  ...props
}: FormSwitchProps) => {
  const { control, getValues } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const value = getValues(name) ?? false

  if (readOnly) {
    return <ReadOnly label={label} value={<Checkbox checked={value} disabled sx={{ mt: -1 }} />} />
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field: { onChange: onValueChange, onBlur, ref, value } }) => (
        <FormGroup>
          <FormControlLabel
            name={name}
            classes={{ label: styles.labelContainer }}
            ref={ref}
            label={
              <FormLabel
                disabled={disabled}
                errorMessage={errorMessage}
                isError={isError}
                label={label}
                infoText={infoText}
                helperText={helperText}
              />
            }
            labelPlacement={labelPlacement}
            control={
              type == 'checkbox' ? (
                <Checkbox
                  disabled={disabled}
                  className={clsx(helperText && styles.paddingTop0)}
                  {...props}
                />
              ) : (
                <Switch disabled={disabled} {...props} />
              )
            }
            checked={value ?? false}
            onChange={(e, val) => {
              onValueChange(e)
              onChange?.(e as ChangeEvent<HTMLInputElement>, val)
            }}
            onBlur={onBlur}
            disabled={disabled}
          />
        </FormGroup>
      )}
    />
  )
}

export default FormSwitch
