'use client'

import type {} from '@mui/lab/themeAugmentation'
import type {} from '@mui/x-tree-view-pro/themeAugmentation'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import type {} from '@mui/material/themeCssVarsAugmentation'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import createTheme from './createTheme'
import type { ThemeColorScheme } from './types'

type Props = {
  children: React.ReactNode
  colorScheme?: ThemeColorScheme
}

const ThemeProvider = ({ children, colorScheme = 'light' }: Props) => {
  const theme = createTheme(colorScheme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider
