import { ChainFeatures, OrganizationFeatures } from 'types/enums/featureFlags'
import { CrudPermission, FeatureType } from 'types/enums/features'

import Routes from 'types/enums/routes'

import { ApplicationScopes } from 'types/enums/scopes'

import type { RoutesConfig } from 'types/routes'

/**
 * Until we release the new frontend main app. For now
 * all pages in the main app area should have these feature flags
 * applied along with any others.
 */
export const mainAppFeatureFlags = [ChainFeatures.NEW_FRONTEND_MAIN_APP]

/**
 * Main App Route Config
 * @constant
 * @type {RouteConfig}
 *
 * The route config is used to store all permissions for each route.
 */
const mainAppRouteConfig: RoutesConfig = {
  // Main App Routes ----------------------------------------------------------->
  [Routes.ROOT]: { scope: ApplicationScopes.ORGANIZATION },
  [Routes.AUTH_LOGIN]: { scope: ApplicationScopes.ALL },

  [Routes.ACCOUNTS]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ALL
  },
  [Routes.ACCOUNT]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ALL
  },
  [Routes.AUTOMATIONS]: {
    featureFlags: [...mainAppFeatureFlags, OrganizationFeatures.AUTOMATIONS],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.BOOKINGS]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ALL
  },
  [Routes.BOOKING]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.BOOKING_CALENDAR]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.DASHBOARD]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.EVENT_DIARY]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.EVENTS]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.GROUPS]: {
    featureFlags: [...mainAppFeatureFlags, OrganizationFeatures.GRC],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.GRC]: {
    featureFlags: [...mainAppFeatureFlags, OrganizationFeatures.GRC],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.TASK_CALENDAR]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.CONTACTS]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ALL
  },
  [Routes.CONTACT]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ALL
  },
  [Routes.DOCUMENTS]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.DOCUMENT]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.PROPOSALS]: {
    featureFlags: [...mainAppFeatureFlags, OrganizationFeatures.PROPOSALS],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.INVOICES]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SCHEDULED_PAYMENTS]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.INVOICE]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.MAIL]: {
    featureFlags: [...mainAppFeatureFlags],
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.OAUTH_AUTHORIZE]: { scope: ApplicationScopes.ALL },
  [Routes.TASKS]: {
    featureFlags: [...mainAppFeatureFlags],
    scope: ApplicationScopes.ALL
  },
  [Routes.TEXT_MESSAGES]: {
    featureFlags: [...mainAppFeatureFlags, OrganizationFeatures.TEXTING],
    scope: ApplicationScopes.ORGANIZATION
  }
}

export default mainAppRouteConfig
