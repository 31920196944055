import React from 'react'

import { format, isValid, parseISO } from 'date-fns'
import { Controller, get, useFormContext, useFormState } from 'react-hook-form'

import DatePicker from 'components/common/date/DatePicker'
import type { FormDatePickerProps } from 'components/forms/FormDatePicker/FormDatePicker.types'

const FormDatePicker = ({ name, required, slotProps, ...props }: FormDatePickerProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  const error = get(errors, name)
  const defaultValue = get(defaultValues, name)

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        const dateValue = value && isValid(parseISO(value.toString())) ? parseISO(value) : null

        return (
          <DatePicker
            {...props}
            inputRef={ref}
            name={name}
            error={!!error}
            errorMessage={error?.message}
            defaultValue={dateValue}
            onChange={(date) => {
              onChange(isValid(date) ? format(date, 'yyyy-MM-dd') : '')
            }}
            slotProps={{ ...slotProps, textField: { onBlur, required, ...slotProps?.textField } }}
            value={dateValue}
          />
        )
      }}
    />
  )
}

export default FormDatePicker
