import { useCallback, useMemo } from 'react'

import useCurrentUserRoles from 'hooks/useCurrentUserRoles'
import useChainContextStore from 'store/chainContext'

import useCurrentUserStore from 'store/currentUser'

import { ChainFeatures } from 'types/enums/featureFlags'
import { ApplicationScopes } from 'types/enums/scopes'

import routeConfig from 'utils/routeConfig'

import type { Organization } from '@repo/et-types'
import type { OrganizationFeatures } from 'types/enums/featureFlags'

import type { CrudPermission, FeatureType } from 'types/enums/features'
import type Routes from 'types/enums/routes'
import type { RouteConfig } from 'types/routes'

type UsePermissionsReturn = {
  canUseMainAppArea: boolean
  canViewChainContext: boolean
  canUseOrgFeature: (feature: OrganizationFeatures, org?: Organization) => boolean
  canUseChainFeature: (feature: ChainFeatures) => boolean
  hasBatchPermission: (feature: FeatureType, crudPermission: CrudPermission) => boolean
  hasOrgPermission: (
    feature: FeatureType,
    crudPermission: CrudPermission,
    organizationId: number
  ) => boolean
  hasPermission: (
    feature: FeatureType,
    crudPermission: CrudPermission,
    organizationId?: number
  ) => boolean
  hasRoutePermissions: (route: Routes) => boolean
}

const checkOrgFeature = (org: Organization, feature: OrganizationFeatures) => {
  const { organization_features } = org

  const featureExists = Boolean(organization_features?.find((f) => f?.feature?.key === feature))

  return featureExists
}

const usePermissions = (): UsePermissionsReturn => {
  const { permissions, chain, currentUserOrganizations, isMultiOrg, userSettings } =
    useCurrentUserStore((state) => ({
      chain: state.chain,
      permissions: state.permissions,
      currentUserOrganizations: state.organizations,
      isMultiOrg: state.isMultiOrg,
      userSettings: state.user_setting
    }))
  const canUseNFE = userSettings?.feature_opt_ins?.nfe_beta

  const { isChainAdmin } = useCurrentUserRoles()
  const [selectedOrganization, selectedChain] = useChainContextStore((state) => [
    state.selectedOrganization,
    state.selectedChain
  ])

  const canUseOrgFeature = useCallback(
    (feature: OrganizationFeatures, org?: Organization): boolean => {
      const givenOrg = currentUserOrganizations.find((o) => o.id === org?.id)

      if (givenOrg) return checkOrgFeature(givenOrg, feature)

      // If the user is in the chain context, we need to check if
      // the user has the feature enabled in any organization.
      if (!selectedOrganization) {
        return currentUserOrganizations?.some((org) => checkOrgFeature(org, feature))
      }

      return checkOrgFeature(selectedOrganization, feature)
    },
    [selectedOrganization, currentUserOrganizations]
  )

  const canUseChainFeature = useCallback(
    (feature: ChainFeatures): boolean => {
      if (!chain) return false

      const { chain_features } = chain

      const featureExists = Boolean(chain_features?.find((f) => f?.feature?.key === feature))

      const isMainAppFlag = feature === ChainFeatures.NEW_FRONTEND_MAIN_APP

      // If the user has turned on the NFE feature flag for themselves, we should allow them to use the new frontend
      // main app area, even if the chain does not have the feature enabled.
      if (isMainAppFlag && !featureExists && canUseNFE) return true

      return featureExists
    },
    [chain, canUseNFE]
  )

  const canViewChainContext = useMemo(
    () =>
      // Check if the chain itself is multi-org
      Boolean(chain && chain?.organizations_count > 1) &&
      // Check if the current user is supposed to see the chain context.
      Boolean(currentUserOrganizations?.length > 1 || isChainAdmin),
    [chain, currentUserOrganizations, isChainAdmin]
  )

  // This function is used to check if the user has a specific permission for a specific organization.
  // If a user is a chain admin, we need to check if the user has the permission for the organization since
  // chain admins don't get special access to organizations even if they have access to the chain.
  const hasOrgPermission = useCallback(
    (feature: FeatureType, crudPermission: CrudPermission, organizationId: number): boolean =>
      Boolean(permissions[organizationId]?.[feature]?.[crudPermission]),
    [permissions]
  )

  // This function is used to check if the user has a specific permission for a specific context.
  // If a user is a chain admin and in the chain context, assume they have most permissions. This is
  // because chain admins have access to all organizations in the chain. In order to check for organization
  // level permissions, we need to use the hasOrgPermission function.
  const hasPermission = useCallback(
    (feature: FeatureType, crudPermission: CrudPermission, organizationId?: number): boolean => {
      const id = organizationId || selectedOrganization?.id || -1

      // If the user is a chain admin and in the chain context, assume they have most permissions,
      // only if no organizationId is provided. If an organizationId is provided, we need to check
      // if the user has the permission for the organization since chain admins don't get special
      // access to organizations even if they have access to the chain.
      if (selectedChain && isChainAdmin && id === -1) return true

      return hasOrgPermission(feature, crudPermission, id)
    },
    [selectedOrganization, selectedChain, isChainAdmin, hasOrgPermission]
  )

  const hasBatchPermission = (feature: FeatureType, crudPermission: CrudPermission): boolean =>
    Object.values(permissions).reduce(
      (res, permission) => res || Boolean(permission?.[feature]?.[crudPermission]),
      false as boolean
    )

  const checkScope = useCallback(
    (scope: RouteConfig['scope']): boolean => {
      if (scope === ApplicationScopes.ORGANIZATION) return Boolean(selectedOrganization)
      else if (scope === ApplicationScopes.CHAIN) {
        // Support for single-org chains
        if (isMultiOrg || canViewChainContext) return Boolean(selectedChain)
      }

      return true
    },
    [selectedOrganization, selectedChain, canViewChainContext, isMultiOrg]
  )

  const checkFeatureFlags = useCallback(
    (featureFlags: RouteConfig['featureFlags']): boolean => {
      let result = true

      // The route has no feature flags, so we can return true
      if (featureFlags === undefined) return result

      featureFlags.forEach((flag) => {
        const allowed =
          canUseChainFeature(flag as ChainFeatures) ||
          canUseOrgFeature(flag as OrganizationFeatures)

        if (!allowed) result = false
      })

      return result
    },
    [canUseChainFeature, canUseOrgFeature]
  )

  const checkPermissions = useCallback(
    (features: RouteConfig['features']): boolean => {
      let result = true

      // The route has no feature permissions, so we can return true
      if (features === undefined) return result

      features.forEach((feature) => {
        feature.permissions.forEach((permission) => {
          const allowed = hasPermission(feature.type, permission)

          if (!allowed) result = false
        })
      })

      return result
    },
    [hasPermission]
  )

  const hasRoutePermissions = useCallback(
    (route: Routes): boolean => {
      const config = routeConfig[route] as RouteConfig
      const hasScopeAccess = checkScope(config?.scope)
      const hasFeatureFlagsAccess = checkFeatureFlags(config?.featureFlags)
      const hasPermissionsAccess = checkPermissions(config?.features)

      return hasScopeAccess && hasFeatureFlagsAccess && hasPermissionsAccess
    },
    [checkScope, checkFeatureFlags, checkPermissions]
  )

  const canUseMainAppArea = useMemo(
    () => Boolean(canUseChainFeature(ChainFeatures.NEW_FRONTEND_MAIN_APP) || canUseNFE),
    [canUseChainFeature, canUseNFE]
  )

  return {
    canUseMainAppArea,
    canViewChainContext,
    canUseOrgFeature,
    canUseChainFeature,
    hasPermission,
    hasOrgPermission,
    hasBatchPermission,
    hasRoutePermissions
  }
}

export default usePermissions
