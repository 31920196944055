import type { ForwardedRef, KeyboardEvent } from 'react'
import React, { forwardRef } from 'react'

import { Chip, TextField } from '@mui/material'

import type { AutocompleteInputProps } from 'components/common/inputs/Autocomplete'
import Loader from 'components/common/layout/Loader'

const AutocompleteInput = forwardRef(
  (
    {
      canCreateNew,
      params,
      onBlur,
      isLoading,
      autocompleteValue,
      NewValueChip,
      ExistingValueChip,
      required,
      TextFieldProps,
      ...props
    }: AutocompleteInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Backspace') event.stopPropagation()
    }

    const getChip = (): JSX.Element | undefined => {
      if (canCreateNew) {
        if (!Array.isArray(autocompleteValue) && autocompleteValue === 'new') {
          return NewValueChip || <Chip variant="filled" label="New" color="success" />
        } else if (autocompleteValue) {
          return ExistingValueChip || <Chip variant="filled" label="Existing" color="primary" />
        }
      }

      return <></>
    }

    const finalProps = { ...params, ...props, ...TextFieldProps }

    const InputProps = {
      ...params?.InputProps,
      ...TextFieldProps?.InputProps,
      endAdornment: (
        <>
          {getChip()}
          {isLoading ? (
            <Loader data-testid="autocomplete-loading" color="inherit" size={20} />
          ) : null}
          {TextFieldProps?.InputProps?.endAdornment}
          {params?.InputProps?.endAdornment}
        </>
      )
    }

    return (
      <TextField
        {...finalProps}
        required={required}
        ref={ref}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        InputProps={InputProps}
      />
    )
  }
)

AutocompleteInput.displayName = 'AutocompleteInput'

export default AutocompleteInput
