import React, { useMemo } from 'react'

import { LoadingButton } from '@mui/lab'

import { Button, Grid, Paper } from '@mui/material'

import type { DialogFormFooterProps } from 'components/forms/DialogForm/DialogForm.types'

import styles from 'styles/DialogForm.module.scss'

import { DIALOG_TYPES } from 'types/dialog'

/**
 * The footer component for a form in a dialog. Current dialogs supported are:
 * Drawer & Modal.
 * @component
 */
const DialogFormFooter = ({
  discardButtonLabel,
  discardButtonProps,
  isLoading = false,
  onDiscard = undefined,
  onSubmit = undefined,
  reverseButtons = false,
  submitButtonLabel = undefined,
  variant = DIALOG_TYPES.DRAWER,
  submitButtonProps
}: DialogFormFooterProps) => {
  const padding = useMemo(() => {
    if (variant === DIALOG_TYPES.DRAWER) {
      return { px: 4, py: 2 }
    } else {
      return { px: 3, py: 3 }
    }
  }, [variant])

  const submitButtonMessage = submitButtonLabel || 'Submit'

  const discardButtonMessage = discardButtonLabel || 'Discard'

  return (
    <Grid
      component={Paper}
      item
      square
      variant={variant === DIALOG_TYPES.DRAWER ? 'outlined' : 'elevation'}
      container
      justifyContent="flex-end"
      alignItems="center"
      columnSpacing={2}
      sx={padding}
      flexDirection={reverseButtons ? 'row-reverse' : 'row'}
      className={styles.footer}>
      {onDiscard && (
        <Grid item>
          <Button variant="outlined" onClick={onDiscard} {...discardButtonProps}>
            {discardButtonMessage}
          </Button>
        </Grid>
      )}
      {onSubmit && (
        <Grid item>
          <LoadingButton
            onClick={onSubmit}
            variant="contained" // by default submit button is contained
            color="primary"
            loading={isLoading}
            {...submitButtonProps}>
            {submitButtonMessage}
          </LoadingButton>
        </Grid>
      )}
    </Grid>
  )
}

export default DialogFormFooter
