import React, { memo, useState } from 'react'

import { Avatar, Box, Typography, useTheme } from '@mui/material'

import { Loader } from '@eventtemple/eventtemple-ui'

import { stringToColor } from 'components/common/avatar/utils'

import type { AvatarProps, TypographyProps } from '@mui/material'

interface OptimizedAvatarProps extends AvatarProps {
  label?: string
  labelProps?: TypographyProps
}

/**
 * OptimizedAvatar
 * Ensures that the avatar image is lazy loaded. This is useful for large lists of avatars.
 *
 * Normally the MUI Avatar component defaults to `object-fit: cover` which can cause performance issues
 * when rendering lists of avatars that may have large images. We use `object-fit: contain` instead.
 */
const OptimizedAvatar = ({ src, label, labelProps, ...props }: OptimizedAvatarProps) => {
  const [loaded, setLoaded] = useState(!src)
  const theme = useTheme()

  const bgColor = label && stringToColor(label)
  const firstLetters = label
    ?.split(' ')
    .map((word) => word?.[0].toUpperCase())
    .join('')

  return (
    <Box position="relative">
      <Avatar
        src={src}
        slotProps={{
          img: {
            sx: { objectFit: 'contain' },
            loading: 'lazy',
            onLoadStart: () => setLoaded(false),
            onLoad: () => setLoaded(true),
            onError: () => setLoaded(true)
          }
        }}
        {...props}
        sx={{ backgroundColor: bgColor, ...props.sx }}>
        {bgColor && (
          <Typography
            variant="body2"
            color={theme.palette.getContrastText(bgColor)}
            {...labelProps}>
            {firstLetters?.slice(0, 2)}
          </Typography>
        )}
      </Avatar>
      {!loaded && <Loader size="1.5rem" style={{ position: 'absolute', top: '15%' }} />}
    </Box>
  )
}

export default memo(OptimizedAvatar)
