import { Box, Chip, Typography, useTheme } from '@mui/material'

const SideNavBadge = ({
  variant = 'default',
  badgeCount
}: {
  variant?: 'default' | 'dot'
  badgeCount: number
}) => {
  const theme = useTheme()

  if (badgeCount === 0) return null

  if (variant === 'dot') {
    return (
      <Box
        position="absolute"
        top=".25rem"
        right="5rem"
        height=".625rem"
        width=".625rem"
        borderRadius={8}
        sx={{ transform: 'scaleX(3)', bgcolor: theme.palette.error.main }}
      />
    )
  }

  return (
    <Chip
      label={
        <Typography variant="inherit" sx={{ color: theme.palette.error.light }}>
          {badgeCount}
        </Typography>
      }
      color="error"
      size="small"
      variant="soft"
    />
  )
}

export default SideNavBadge
