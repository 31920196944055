import React from 'react'

import { get as lodashGet } from 'lodash'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import DateRangePicker from 'components/common/date/DateRangePicker'
import type { FormDateRangePickerProps } from 'components/forms/FormDateRangePicker/FormDateRangePicker.types'

const FormDateRangePicker = ({ name, required, slotProps, ...props }: FormDateRangePickerProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const defaultValue = lodashGet(defaultValues, name)

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <DateRangePicker
          {...props}
          name={name}
          error={isError}
          errorMessage={errorMessage}
          defaultValue={defaultValue}
          onChange={onChange}
          slotProps={{
            ...slotProps,
            textField: { onBlur, required, inputRef: ref, ...slotProps?.textField }
          }}
          value={value}
        />
      )}
    />
  )
}

export default FormDateRangePicker
