import React from 'react'

import { isValid } from 'date-fns'
import { Controller, get, useFormContext, useFormState } from 'react-hook-form'

import DateTimePicker from 'components/common/date/DateTimePicker'
import type { FormDateTimePickerProps } from 'components/forms/FormDateTimePicker/FormDateTimePicker.types'

const FormDateTimePicker = ({ name, required, slotProps, ...props }: FormDateTimePickerProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  const error = get(errors, name)
  const defaultValue = get(defaultValues, name)

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        const dateTimeValue = value ? new Date(value) : null

        return (
          <DateTimePicker
            {...props}
            inputRef={ref}
            name={name}
            error={!!error}
            errorMessage={error?.message}
            defaultValue={dateTimeValue}
            onChange={(dateTime) => {
              const validDateTime = isValid(dateTime)

              onChange(validDateTime ? dateTime.toISOString() : dateTime)
            }}
            slotProps={{ ...slotProps, textField: { onBlur, required, ...slotProps?.textField } }}
            value={dateTimeValue}
          />
        )
      }}
    />
  )
}

export default FormDateTimePicker
