import React from 'react'

import { InfoRounded } from '@mui/icons-material'
import { FormHelperText, FormLabel as MUIFormLabel, Tooltip } from '@mui/material'

import type { FormLabelProps } from 'components/forms/FormLabel/FormLabel.types'

import styles from 'styles/FormSwitch.module.scss'

const FormLabel = ({
  disabled,
  label,
  infoText,
  errorMessage,
  isError,
  helperText,
  ...props
}: FormLabelProps) => (
  <div className={helperText && styles.withHelperText}>
    <MUIFormLabel
      className={styles.formLabel}
      sx={{ color: disabled ? 'text.disabled' : 'text.primary' }}
      {...props}>
      {label}
      {infoText && (
        <Tooltip title={infoText}>
          <InfoRounded color={disabled ? 'disabled' : 'action'} fontSize="small" />
        </Tooltip>
      )}
    </MUIFormLabel>
    {isError && errorMessage ? (
      <FormHelperText sx={{ m: '0' }} className="Mui-error">
        {errorMessage}
      </FormHelperText>
    ) : (
      helperText && (
        <FormHelperText sx={{ color: disabled ? 'text.disabled' : 'text.secondary', m: '0' }}>
          {helperText}
        </FormHelperText>
      )
    )}
  </div>
)

export default FormLabel
