import React, { useMemo } from 'react'

import { Close } from '@mui/icons-material'
import { Grid, IconButton, Paper, Typography } from '@mui/material'

import clsx from 'clsx'

import type { DialogFormHeaderProps } from 'components/forms/DialogForm/DialogForm.types'

import styles from 'styles/DialogForm.module.scss'

import { DIALOG_TYPES } from 'types/dialog'
/**
 * The header component for a form in a dialog. Current dialogs supported are:
 * Drawer & Modal.
 * @component
 */
const DialogFormHeader = ({
  headerLabel = undefined,
  variant = DIALOG_TYPES.DRAWER,
  onClose
}: DialogFormHeaderProps) => {
  const padding = useMemo(() => {
    if (variant === DIALOG_TYPES.DRAWER) return { px: 4, py: 2 }

    return { px: 2, py: 2 }
  }, [variant])

  const headerContent = useMemo(() => {
    if (!headerLabel) return null

    const isArray = Array.isArray(headerLabel)

    if (!isArray && typeof headerLabel === 'string') {
      return <Typography variant="h5">{headerLabel}</Typography>
    } else if (!isArray) return headerLabel

    return (
      <Grid item>
        <Grid container direction="column" component={Paper}>
          {headerLabel.map((value, index) => (
            <Grid item key={index}>
              <Typography
                variant={index === headerLabel.length - 1 ? 'h5' : 'caption'}
                color={index === headerLabel.length - 1 ? 'text.primary' : 'text.secondary'}>
                {value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    )
  }, [headerLabel])

  return (
    <Grid
      item
      className={clsx(styles.header, variant == DIALOG_TYPES.DRAWER && styles.drawerHeader)}
      sx={padding}>
      {headerContent}
      {onClose && (
        <Grid item>
          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default DialogFormHeader
