import { SubscriptionStatus } from '@repo/et-types/subscription'
import { create } from 'zustand'

import { SIGN_IN_URL } from 'utils/constants/global'

import type { Chain, CurrentUser, Organization, OrganizationMembership } from '@repo/et-types'
import type ChainFeature from '@repo/et-types/chainFeature'
import type { OrganizationMap } from '@repo/et-types/organization'

import type OrganizationFeature from '@repo/et-types/organizationFeature'

import type { Permission, PermissionMap } from '@repo/et-types/rolePermission'

export interface CurrentUserLoadState {
  isAuthenticated: boolean
  isLoading: boolean
  isError: boolean
}

type FroalaS3Options = {
  bucket: string
  region: string
  keyStart: string
  params: {
    acl: string
    AWSAccessKeyId: string
    policy: string
    signature: string
  }
}

export interface CurrentUserState extends CurrentUser, CurrentUserLoadState {
  organizationIds: number[]
  organizationsById: OrganizationMap
  permissions: PermissionMap
  isMultiOrg: boolean
  froalaS3Options: FroalaS3Options
  isETAdmin: boolean
  getLoginUrl: (redirectUrl: string) => string
  selectOrganizations: (organizationIds: number[]) => void
  setCurrentUser: (currentUser: CurrentUser) => void
  setOrganizations: (organizations: Organization[]) => void
  updateCurrentUser: (newValues: Partial<CurrentUserState>) => void
  updateOrganization: (organization: Partial<Organization>) => Organization
}

const defaultCurrentUserState: CurrentUser = {
  chain_admin: false,
  chain_organizations: [],
  custom_fields: [],
  deactivated: false,
  email: '',
  first_name: '',
  froalaS3Options: {},
  id: 0,
  isAuthenticated: false,
  isETAdmin: false,
  isError: false,
  isLoading: true,
  isMultiOrg: false,
  last_name: '',
  name: '',
  chain_features: [],
  organization_features: [],
  organization_memberships: [],
  organizations: [],
  billed_organization_id: 0
}

const getPermissionsByOrgId = (orgMemberships: OrganizationMembership[]) => {
  const permissionsByOrgId: PermissionMap = {}

  orgMemberships.forEach((orgMembership) => {
    permissionsByOrgId[orgMembership.organization_id] ||= {} as Permission
    orgMembership.role?.role_permissions?.forEach((permission) => {
      permissionsByOrgId[orgMembership.organization_id][permission.feature] = permission
    })
  })

  return permissionsByOrgId
}

const getOrganizationsById = (currentUser: CurrentUser) => {
  const orgs = currentUser?.chain?.global_accounts
    ? currentUser?.chain_organizations
    : currentUser?.organizations

  return orgs?.reduce(
    (acc, org) => {
      acc[org.id] = org
      return acc
    },
    {} as Record<number, Organization>
  )
}

const getOrganizationsWithMembership = (currentUser: CurrentUser) =>
  currentUser.organizations
    ?.map((org) => ({
      ...org,
      membership: currentUser.organization_memberships?.find((om) => om.organization_id === org.id),
      organization_features: currentUser.organization_features?.filter(
        (feature: OrganizationFeature) => feature.organization_id === org.id
      )
    }))
    .filter((org) => org.subscription?.status !== SubscriptionStatus.CANCELED) // canceled orgs are inaccessible to users

const getChainWithFeatures = (currentUser: CurrentUser): Chain =>
  ({
    ...currentUser.chain,
    chain_features: currentUser.chain_features?.filter(
      (feature: ChainFeature) => feature.chain_id === currentUser?.chain?.id
    )
  }) as Chain

const useCurrentUserStore = create<CurrentUserState>((set) => ({
  ...defaultCurrentUserState,
  isAuthenticated: false,
  isLoading: true,
  isError: false,
  isMultiOrg: false,
  organizationIds: [],
  organizationsById: {},
  permissions: {},
  isETAdmin: false,
  froalaS3Options: {
    bucket: '',
    region: '',
    keyStart: '',
    params: {
      acl: '',
      AWSAccessKeyId: '',
      policy: '',
      signature: ''
    }
  },
  getLoginUrl: (redirectUrl: string) =>
    `${SIGN_IN_URL}?return_to=${encodeURIComponent(redirectUrl)}`,
  selectOrganizations: (organizationIds: number[]) =>
    set((state) => ({ ...state, organizationIds })),
  setCurrentUser: (currentUser: CurrentUser) =>
    set((state) => ({
      ...state,
      ...currentUser,
      isLoading: false,
      isError: false,
      isAuthenticated: true,
      billed_organization_id: currentUser.billed_organization_id,
      isMultiOrg: currentUser.organizations?.length > 1,
      froalaS3Options: currentUser.froala_s3_options || {},
      permissions: getPermissionsByOrgId(currentUser.organization_memberships || []),
      organizationsById: getOrganizationsById(currentUser),
      organizations: getOrganizationsWithMembership(currentUser),
      chain: getChainWithFeatures(currentUser),
      chain_organizations: currentUser.chain_organizations.filter(
        (org) => org.subscription?.status !== SubscriptionStatus.CANCELED
      )
    })),
  setOrganizations: (organizations: Organization[]) =>
    set((state) => ({ ...state, organizations })),
  updateCurrentUser: (newValues: Partial<CurrentUserState>) =>
    set((state) => ({ ...state, ...newValues })),
  updateOrganization: (organization: Partial<Organization>): Organization => {
    let updatedOrg = organization

    set((state) => ({
      ...state,
      organizations: state.organizations.map((org) => {
        let result = org

        if (org.id === organization.id) {
          result = { ...org, ...organization }
          updatedOrg = result
        }

        return result
      })
    }))

    return updatedOrg as Organization
  }
}))

export default useCurrentUserStore
