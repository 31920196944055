import React from 'react'

import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers-pro'

import { isValid } from 'date-fns'

import type { DateTimePickerProps } from 'components/common/date/DateTimePicker'
import ReadOnly from 'components/common/inputs/ReadOnly'

import { useFormatDateTime } from 'hooks/useFormatDate'

const DateTimePicker = ({
  defaultValue,
  error,
  errorMessage,
  fullWidth,
  helperText,
  label,
  readOnly,
  readOnlyFormatter,
  ReadOnlyProps,
  slotProps,
  value,
  ...props
}: DateTimePickerProps) => {
  const { format, use24HourClock, formatDateTime } = useFormatDateTime()

  if (readOnly) {
    const readOnlyValue = value ?? defaultValue

    const formattedValue = () => {
      if (!isValid(readOnlyValue)) return ''

      return readOnlyFormatter
        ? readOnlyFormatter(readOnlyValue)
        : formatDateTime(readOnlyValue, format)
    }

    return <ReadOnly label={label} value={formattedValue()} {...ReadOnlyProps} />
  }

  return (
    // @ts-expect-error - MUIDateTimePickerProps TValue does not allow us to set Date type due to
    // and incorrect type definition in the MUI library.
    <MUIDateTimePicker<Date>
      value={value}
      label={label}
      format={format}
      ampm={!use24HourClock}
      {...props}
      slotProps={{
        ...slotProps,
        textField: (state) => ({
          error,
          helperText: error ? errorMessage : helperText,
          fullWidth,
          ...(typeof slotProps?.textField === 'function'
            ? slotProps.textField(state)
            : slotProps?.textField)
        })
      }}
    />
  )
}

export default DateTimePicker
