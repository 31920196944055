import { useMemo } from 'react'

import useMainSideNavItems from 'hooks/common/layout/sideNav/useMainSideNavItems'
import useSettingSideNavItems from 'hooks/common/layout/sideNav/useSettingSideNavItems'
import useNavigation from 'hooks/common/useNavigation'

import type { MouseEvent, ReactNode } from 'react'

export type SideNavItem = {
  badgeCount?: number
  component?: ReactNode
  external?: boolean
  hidden?: boolean
  href?: string
  icon?: ReactNode
  isCannyChangeLog?: boolean
  label?: string
  onClick?: () => void
  onItemHover?: (e: MouseEvent<HTMLDivElement> | null, items: SideNavItem[]) => void
  showArrow?: boolean
  subItems?: SideNavItem[]
  variant?: 'default' | 'header' | 'dropdown'
  defaultExpanded?: boolean
}

const useSideNav = () => {
  const { isSettingNav } = useNavigation()

  const mainItems = useMainSideNavItems()
  const settingsItems = useSettingSideNavItems()

  const items: SideNavItem[] = useMemo(
    () => (isSettingNav ? settingsItems : mainItems),
    [isSettingNav, mainItems, settingsItems]
  )

  return { items, isSettingNav }
}

export default useSideNav
