import type { ReactNode } from 'react'
import React from 'react'

import { MoreVert } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

import { GridActionsCellItem } from '@mui/x-data-grid-pro'

import type { DropdownButtonProps } from 'components/common/buttons/DropdownButton'
import DropdownButton from 'components/common/buttons/DropdownButton'

import { iconMapper } from 'components/common/DataGrid/IconWithText'

import { ACTIONS_COLUMN_ID, ACTION_BUTTON_SIZE } from 'hooks/dataGrid/constants'

import type { GridActionsColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro'

import type { Base } from '@repo/et-types'

import type { TableAction } from 'types/dataGrid'

const useDataGridActions = <T extends Base>({
  actions,
  actionsDropdownProps,
  customActionsColumn,
  inline = false
}: {
  actions: TableAction<T>[]
  actionsDropdownProps?: DropdownButtonProps
  customActionsColumn?: (params: GridRenderCellParams<T>) => ReactNode
  inline?: boolean
}): GridActionsColDef<T> | null => {
  const getVisibleActions = (row) =>
    actions.filter((action) => (action.hidden ? !action.hidden(row) : true))

  const performAction = (action: TableAction<T>, row: GridRowParams<T>['row']) =>
    action.onClick(row)

  const columnWidth =
    !inline || customActionsColumn
      ? ACTION_BUTTON_SIZE + 8
      : actions?.length * ACTION_BUTTON_SIZE + 16
  const actionsColumn: GridActionsColDef<T> = {
    field: ACTIONS_COLUMN_ID,
    type: ACTIONS_COLUMN_ID,
    getActions: () => [],
    width: columnWidth,
    minWidth: columnWidth,
    resizable: false
  }

  if (customActionsColumn) {
    actionsColumn.renderCell = (params) => customActionsColumn(params)
  } else {
    if (inline) {
      actionsColumn.getActions = (params) =>
        getVisibleActions(params.row).map((action) => {
          let disabled = false

          if (action?.disabled) disabled = action.disabled(params.row)

          if (action?.hasPermission && !action.hasPermission(params.row)) disabled = true

          const tooltipLabel = (action?.tooltip && action.tooltip(params.row)) ?? action.label

          return (
            <>
              <Tooltip key={action.label} title={tooltipLabel} arrow>
                <div>
                  <GridActionsCellItem
                    label={action.label}
                    icon={action.icon ?? iconMapper(action.iconType)}
                    disabled={disabled}
                    onClick={() => performAction(action, params.row)}
                    size="small"
                  />
                </div>
              </Tooltip>
            </>
          )
        })
    } else {
      actionsColumn.renderCell = (params) => (
        <DropdownButton
          tooltipTitle="More"
          customButton={
            <IconButton size="small">
              <MoreVert fontSize="inherit" />
            </IconButton>
          }
          {...actionsDropdownProps}
          options={getVisibleActions(params.row).map((action) => {
            const disabled = action?.disabled?.(params.row) ?? false
            const hasPermission = action?.hasPermission?.(params.row) ?? true
            const tooltip = action?.tooltip?.(params.row) ?? action.label

            return {
              label: action.label,
              onClick: () => performAction(action, params.row),
              disabled: Boolean(disabled || !hasPermission),
              tooltip,
              ...action.menuItemProps
            }
          })}
        />
      )
    }
  }

  return actionsColumn
}

export default useDataGridActions
