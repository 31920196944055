import React, { type ForwardedRef, forwardRef } from 'react'

import { CircularProgress } from '@mui/material'

import type { LoaderProps } from 'components/common/layout/Loader'

import styles from 'styles/Loader.module.scss'

const Loader = forwardRef(
  (props: LoaderProps, ref: ForwardedRef<JSX.Element>): JSX.Element => (
    <div className={styles.loader} data-testid="Loader">
      <CircularProgress {...props} ref={ref} />
    </div>
  )
)

Loader.displayName = 'Loader'

export default Loader
