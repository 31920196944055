import { Grow, Paper, Popper, Stack } from '@mui/material'

import SideNavItem from 'components/layout/SideNav/SideNavItem'

import type { SideNavItem as SideNavItemType } from 'hooks/common/layout/sideNav/useSideNav'
import type { MouseEvent } from 'react'

type SideNavPopperProps = {
  anchorEl: HTMLElement | null
  items: SideNavItemType[]
  onMouseEnter: (e: MouseEvent<HTMLDivElement>) => void
  onMouseLeave: () => void
}

const SideNavPopper = ({ anchorEl, items, onMouseEnter, onMouseLeave }: SideNavPopperProps) => (
  <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} placement="right-start" transition>
    {({ TransitionProps }) => (
      <Grow {...TransitionProps}>
        <Paper
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          sx={{ boxShadow: (theme) => theme?.customShadows?.dropdown }}>
          <Stack>{items?.map((item, index) => <SideNavItem key={index} {...item} />)}</Stack>
        </Paper>
      </Grow>
    )}
  </Popper>
)

export default SideNavPopper
