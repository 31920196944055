import { useQuery } from '@tanstack/react-query'

import useCurrentUserResource from 'hooks/currentUser/useCurrentUserResource'
import useInitializeHotJarUser from 'hooks/thirdParty/useInitializeHotJarUser'
import useInitializeSentryUser from 'hooks/thirdParty/useInitializeSentryUser'
import useCurrentUserStore from 'store/currentUser'

const useCurrentUser = () => {
  const currentUserResource = useCurrentUserResource()
  const currentUserIncludes = currentUserResource.defaultInclude.toString()

  const { setCurrentUser, updateCurrentUser } = useCurrentUserStore((state) => ({
    setCurrentUser: state.setCurrentUser,
    updateCurrentUser: state.updateCurrentUser
  }))

  useInitializeHotJarUser()
  useInitializeSentryUser()

  const { isLoading, error } = useQuery({
    queryKey: [...currentUserResource.queryKey, currentUserIncludes],
    queryFn: () => currentUserResource.get(undefined, { include: currentUserIncludes }),
    retry: false,
    onSuccess: (data) => {
      if (!data?.normalizedData) return

      setCurrentUser({
        ...data.normalizedData,
        isETAdmin: Boolean(data?.meta?.is_admin_logged_in),
        isAuthenticated: true
      })
    },
    onSettled: () => {
      updateCurrentUser({ isLoading: false })
    }
  })

  return { isLoading, error }
}

export default useCurrentUser
