import React from 'react'

import { get as lodashGet } from 'lodash'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import Radio from 'components/common/buttons/Radio'
import type { FormRadioProps } from 'components/forms/FormRadio/FormRadio.types'

const FormRadio = ({ name, required, ...props }: FormRadioProps) => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field: { onChange, onBlur, ref, value } }) => (
        <Radio
          {...props}
          name={name}
          ref={ref}
          onChange={(checked) => onChange(checked)}
          onBlur={onBlur}
          value={value}
          isError={isError}
          errorMessage={errorMessage}
        />
      )}
    />
  )
}

export default FormRadio
