import React from 'react'

import { MeasuringStrategy, closestCenter } from '@dnd-kit/core'

import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import DragDropContext from 'components/dnd/DragDropContext'

import type { SortableProps } from 'components/dnd/Sortable/Sortable.types'
import SortableItem from 'components/dnd/SortableItem'

const Sortable = <T,>({ id, items, renderItem, disabled, ...props }: SortableProps<T>) => {
  const getItemIds = () => {
    if (typeof items[0] === 'string') return items as string[]

    items = items as ({ id: string | number } & T)[]

    return items.map((item) => `${item.id}`)
  }

  const getItemId = (index: number) => {
    if (typeof items[0] === 'string') return items[index] as string

    items = items as ({ id: string | number } & T)[]

    return `${items[index].id}`
  }

  return (
    <DragDropContext
      collisionDetection={closestCenter}
      modifiers={[restrictToParentElement, restrictToVerticalAxis]}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      {...props}>
      <SortableContext
        id={id}
        items={getItemIds()}
        disabled={disabled}
        strategy={verticalListSortingStrategy}>
        {items?.map((item, index) => (
          <SortableItem key={getItemId(index)} itemId={getItemId(index)}>
            {({ ...props }) => renderItem({ item, index, ...props })}
          </SortableItem>
        ))}
      </SortableContext>
    </DragDropContext>
  )
}

export default Sortable
