import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import type { Chain, Organization } from '@repo/et-types'

export const ET_CHAIN_CONTEXT_KEY = 'chain-context'

interface ChainContextState {
  persistedChainId?: number
  persistedOrganizationId?: number
  selectedChain?: Chain
  selectedOrganization?: Organization
  setSelectedChain: (chain: Chain | undefined) => void
  setSelectedOrganization: (organization: Organization | undefined) => void
}

const useChainContextStore = create<ChainContextState>()(
  persist(
    (set) => ({
      persistedChainId: undefined,
      persistedOrganizationId: undefined,
      selectedChain: undefined,
      selectedOrganization: undefined,
      setSelectedChain: (chain: Chain | undefined) =>
        set((state) => ({
          ...state,
          selectedChain: chain,
          selectedOrganization: undefined
        })),
      setSelectedOrganization: (organization: Organization | undefined) =>
        set((state) => ({
          ...state,
          selectedChain: undefined,
          selectedOrganization: organization
        }))
    }),
    {
      name: 'chain-context',
      partialize: (state) =>
        ({
          persistedChainId: state.selectedChain?.id,
          persistedOrganizationId: state.selectedOrganization?.id
        }) as Partial<ChainContextState>
    }
  )
)

export default useChainContextStore
