import React, { forwardRef } from 'react'

import { FormControl, FormControlLabel, Radio as MUIRadio } from '@mui/material'

import type { RadioProps } from 'components/common/buttons/Radio'
import FormLabel from 'components/forms/FormLabel'

const Radio = forwardRef(
  (
    {
      disabled,
      errorMessage,
      helperText,
      infoText,
      isError,
      label,
      onChange,
      readOnly,
      required,
      value,
      ...props
    }: RadioProps,
    ref
  ) => {
    const handleOnClick = () => {
      if (!readOnly && !disabled && onChange) onChange(!value)
    }

    return (
      <FormControl>
        <FormControlLabel
          control={<MUIRadio />}
          onClick={handleOnClick}
          checked={Boolean(value)}
          value={value}
          disabled={disabled || readOnly}
          ref={ref}
          label={
            <FormLabel
              disabled={disabled}
              errorMessage={errorMessage}
              isError={isError}
              label={label}
              infoText={infoText}
              helperText={helperText}
              required={required}
              focused={false}
            />
          }
          {...props}
        />
      </FormControl>
    )
  }
)

Radio.displayName = 'Radio'

export default Radio
