import { type ReactNode, Suspense } from 'react'

import dynamic from 'next/dynamic'

import CannyProvider from 'providers/CannyProvider'
import GlobalContextProvider from 'providers/GlobalContextProvider'
import IntercomProvider from 'providers/IntercomProvider'
import ProfitwellProvider from 'providers/ProfitwellProvider'
import UserProvider from 'providers/UserProvider'

const MainAppProviders = ({ children }: { children: ReactNode }) => (
  <Suspense fallback={null}>
    <UserProvider>
      <ProfitwellProvider>
        <CannyProvider>
          <IntercomProvider>
            <GlobalContextProvider>{children}</GlobalContextProvider>
          </IntercomProvider>
        </CannyProvider>
      </ProfitwellProvider>
    </UserProvider>
  </Suspense>
)

export default dynamic(() => Promise.resolve(MainAppProviders), { ssr: false })
