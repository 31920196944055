import React, { forwardRef } from 'react'

import { Icon } from '@iconify/react'
import Box from '@mui/material/Box'

import type { IconifyProps } from 'components/iconify/types'

import type { BoxProps } from '@mui/material/Box'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  icon: IconifyProps
}

const Iconify = forwardRef<SVGElement, Props>(({ icon, width = 20, sx, ...others }: Props, ref) => (
  /* @ts-expect-error - Ignore prop type collision */
  <Box
    ref={ref}
    component={Icon}
    className="component-iconify"
    icon={icon}
    sx={{ width, height: width, ...sx }}
    {...others}
  />
))

Iconify.displayName = 'Iconify'

export default Iconify
