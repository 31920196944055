import React from 'react'

import { get as lodashGet } from 'lodash'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import RadioGroup from 'components/common/buttons/RadioGroup'

import type { FormRadioGroupProps } from 'components/forms/FormRadioGroup/FormRadioGroup.types'

const FormRadioGroup = ({
  name,
  required,
  disabled,
  onValueChange,
  ...props
}: FormRadioGroupProps) => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, onBlur, ref, value } }) => (
        <RadioGroup
          {...props}
          name={name}
          isError={isError}
          errorMessage={errorMessage}
          required={required}
          disabled={disabled}
          onChange={(e, value) => {
            onValueChange?.(value)
            onChange(e)
          }}
          onBlur={onBlur}
          ref={ref}
          value={value || ''}
        />
      )}
    />
  )
}

export default FormRadioGroup
