import { useMemo } from 'react'

import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_REORDER_COL_DEF } from '@mui/x-data-grid-pro'

import useColumnPropertiesAsColumnDefs from 'hooks/dataGrid/useColumnPropertiesAsColumnDefs'
import useDataGridActions from 'hooks/dataGrid/useDataGridActions'

import type { GridColDef } from '@mui/x-data-grid-pro'

const selectionColumn: GridColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  maxWidth: 40,
  minWidth: 40
}

const defaultReorderColumn: GridColDef = {
  ...GRID_REORDER_COL_DEF,
  maxWidth: 40,
  minWidth: 40
}

const useDataGridColumns = ({
  actions,
  actionsDropdownProps,
  columnProperties,
  columnSizes,
  customActionsColumn,
  customReorderColumn,
  inlineActions
}) => {
  const columnPropertyColumns = useColumnPropertiesAsColumnDefs({
    columnProperties,
    columnSizes
  })

  const actionsColumn = useDataGridActions({
    actions,
    actionsDropdownProps,
    customActionsColumn,
    inline: inlineActions
  })

  // Memoize the columns to prevent their size from being recalculated on every render.
  const columns = useMemo(() => {
    const result = [
      customReorderColumn || defaultReorderColumn,
      selectionColumn,
      ...columnPropertyColumns
    ]

    if (actions?.length > 0 || customActionsColumn) result.push(actionsColumn)

    return result
  }, [
    actions?.length,
    actionsColumn,
    customActionsColumn,
    customReorderColumn,
    columnPropertyColumns
  ])

  return columns
}

export default useDataGridColumns
