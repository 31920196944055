import React from 'react'

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Button, Collapse, Paper, Stack, Typography, useTheme } from '@mui/material'

import { useToggle } from 'usehooks-ts'

import Iconify from 'components/iconify'

import MailAttachmentPreview from 'components/mail/MailAttachment/MailAttachment'

import type { MailAttachmentsProps } from 'components/mail/MailAttachments/MailAttachments.types'

import { downloadURLFilesToZip } from 'utils/download'

const MailAttachments = ({ attachments, labels }: MailAttachmentsProps) => {
  const theme = useTheme()

  const [open, toggleOpen] = useToggle(false)

  return (
    <Stack
      data-testid="MailAttachments"
      mb={2}
      bgcolor={theme.palette.grey[100]}
      p={0.5}
      component={Paper}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Button
          onClick={toggleOpen}
          startIcon={<Iconify icon="solar:paperclip-2-bold" height={16} width={16} />}
          endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}>
          <Typography variant="caption">
            {attachments?.length} {labels?.attachments || 'Attachments'}
          </Typography>
        </Button>
        <Button
          startIcon={<Iconify icon="solar:download-bold" height={16} width={16} />}
          onClick={() => downloadURLFilesToZip(attachments, 'attachments.zip')}>
          {labels?.download || 'Download'} (.zip)
        </Button>
      </Stack>
      <Collapse in={open}>
        <Stack columnGap={0.5} mt={1} direction="row">
          {attachments.map((attachment) => (
            <MailAttachmentPreview key={attachment?.id} attachment={attachment} />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default MailAttachments
