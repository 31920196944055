import { OrganizationFeatures } from 'types/enums/featureFlags'

import { CrudPermission, FeatureType } from 'types/enums/features'
import Routes from 'types/enums/routes'

import { ApplicationScopes } from 'types/enums/scopes'

import type { RoutesConfig } from 'types/routes'

/**
 * Settings Route Config
 * @constant
 * @type {RouteConfig}
 *
 * The route config is used to store all permissions for each route.
 */
const settingsRouteConfig: RoutesConfig = {
  [Routes.SETTINGS]: { scope: ApplicationScopes.ALL },
  [Routes.SETTINGS_CALENDAR_INTEGRATIONS]: { scope: ApplicationScopes.ORGANIZATION },
  [Routes.SETTINGS_GUEST_PORTAL]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    featureFlags: [OrganizationFeatures.GUEST_PORTAL],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_USER_PROFILE]: { scope: ApplicationScopes.ALL },
  [Routes.SETTINGS_EMAIL]: {
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_EMAIL_TEMPLATES]: {
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_EMAIL_TEMPLATES_CREATE]: {
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_EMAIL_TEMPLATES_EDIT]: {
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_ORGANIZATION_OVERVIEW]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_SETUP_OVERVIEW]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_ORGANIZATION_PREFERENCES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_ORGANIZATIONS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.CHAIN
  },
  [Routes.SETTINGS_USERS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_INTAKE_FORMS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_INTAKE_FORM]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_TEXT_TEMPLATES]: {
    featureFlags: [OrganizationFeatures.TEXTING],
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_TEXT_TEMPLATES_CREATE]: {
    featureFlags: [OrganizationFeatures.TEXTING],
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_TEXT_TEMPLATES_EDIT]: {
    featureFlags: [OrganizationFeatures.TEXTING],
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_TEXTING]: {
    featureFlags: [OrganizationFeatures.TEXTING],
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_BILLING_SETTINGS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_TAXES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_PIPELINES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_SPACES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_ROOMS]: {
    featureFlags: [OrganizationFeatures.GRC],
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_ROOM_PICKUPS]: {
    featureFlags: [OrganizationFeatures.LNR],
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_LOCATIONS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_MENUS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_MENU_ITEMS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_MENU_CATEGORIES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_PACKAGES]: {
    featureFlags: [OrganizationFeatures.PACKAGES],
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_BUDGETS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_IMPORTS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_API_INTEGRATIONS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.CHAIN
  },
  [Routes.SETTINGS_OAUTH2_APPLICATIONS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.CHAIN
  },
  [Routes.SETTINGS_OAUTH2_APPLICATION]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.CHAIN
  },
  [Routes.SETTINGS_WEBHOOKS_INTEGRATIONS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_MARKETPLACE]: {
    featureFlags: [OrganizationFeatures.MARKETPLACE],
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_ZAPIER_INTEGRATIONS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_STRIPE_INTEGRATIONS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_DOCUMENT_TEMPLATES]: {
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_DOCUMENT_TEMPLATE]: {
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_PROPOSAL_TEMPLATES]: {
    featureFlags: [OrganizationFeatures.PROPOSALS],
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_PROPOSAL_TEMPLATE]: {
    featureFlags: [OrganizationFeatures.PROPOSALS],
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_DOCUMENT_TEMPLATE_LIBRARY]: {
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_AUTOMATION_SEQUENCES]: {
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    featureFlags: [OrganizationFeatures.AUTOMATIONS],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_WORKFLOW_TEMPLATES]: {
    featureFlags: [OrganizationFeatures.WORKFLOWS],
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_FILES]: {
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.CREATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_SETUP_VALUES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_REVENUE_CATEGORIES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_REFERRAL_SOURCES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_LOST_REASONS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_BOOKING_TYPES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_EVENT_TYPES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_TASK_TYPES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_SETUP_STYLES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_SUBSCRIPTION]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ORGANIZATION
  },
  [Routes.SETTINGS_ACCOUNT_TYPES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_MARKET_SEGMENTS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_DOCUMENT_TYPES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_PAYMENT_TYPES]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_SPACE_CLOSURE_REASONS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_CUSTOM_FIELDS]: {
    features: [{ type: FeatureType.SETTINGS, permissions: [CrudPermission.UPDATE] }],
    scope: ApplicationScopes.ALL
  },
  [Routes.SETTINGS_PMS_INTEGRATIONS]: {
    featureFlags: [OrganizationFeatures.PMS],
    features: [{ type: FeatureType.CRM, permissions: [CrudPermission.READ] }],
    scope: ApplicationScopes.ORGANIZATION
  }
}

export default settingsRouteConfig
