import type { MouseEvent } from 'react'
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { ArrowDropDown } from '@mui/icons-material'
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material'

import type { DropdownButtonProps } from 'components/common/buttons/DropdownButton'

const DropdownButton = forwardRef(
  (
    {
      MenuProps,
      options,
      children,
      customButton,
      endIcon = <ArrowDropDown />,
      onClose,
      onOpen,
      variant = 'contained',
      tooltipTitle,
      ...others
    }: DropdownButtonProps,
    ref
  ) => {
    const buttonRef = useRef(null)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (e?: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e?.stopPropagation()
      e?.preventDefault()

      onOpen?.()

      setAnchorEl(e.currentTarget)
    }

    const handleClose = (e?: MouseEvent) => {
      e?.stopPropagation()
      e?.preventDefault()

      onClose?.()

      setAnchorEl(null)
    }

    const handleOptionClick = (e: MouseEvent<HTMLLIElement>, onClick: () => void) => {
      e?.stopPropagation()
      e?.preventDefault()
      onClick()
      handleClose()
    }

    useImperativeHandle(ref, () => ({
      close: handleClose,
      open: () => buttonRef.current?.click(),
      getOpenState: () => open
    }))

    return (
      <>
        <Tooltip title={tooltipTitle} arrow disableHoverListener={!tooltipTitle} disableInteractive>
          {customButton ? (
            <Box
              ref={buttonRef}
              onClick={handleClick}
              display="inline-block"
              component="div"
              {...others}>
              {customButton}
            </Box>
          ) : (
            <Button
              ref={buttonRef}
              onClick={handleClick}
              endIcon={endIcon}
              variant={variant}
              {...others}>
              {children}
            </Button>
          )}
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: { sx: { width: anchorEl && anchorEl.offsetWidth, minWidth: 'max-content' } }
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          {...MenuProps}>
          {options.map((option, index) => (
            <Tooltip arrow placement="bottom" key={index} title={option.tooltip}>
              <div>
                <MenuItem
                  key={index}
                  {...option}
                  onClick={(e) => handleOptionClick(e, option?.onClick)}>
                  {option.icon ? <ListItemIcon>{option.icon}</ListItemIcon> : null}
                  <ListItemText>{option.label}</ListItemText>
                </MenuItem>
                {option.children}
              </div>
            </Tooltip>
          ))}
        </Menu>
      </>
    )
  }
)

DropdownButton.displayName = 'DropdownButton'

export default DropdownButton
