import React from 'react'

import type { ReactNode } from 'react'

import { Box } from '@mui/material'
import { FormProvider } from 'react-hook-form'

import type { FormBaseProps } from 'components/forms/FormBase/FormBase.types'

import type { UseFormReturn } from 'react-hook-form'

const Container = <T,>({
  children,
  methods
}: {
  children: ReactNode
  methods: UseFormReturn<T>
}) => {
  if (methods) return <FormProvider {...methods}>{children}</FormProvider>

  return children
}

/**
 * The base component for a form. Accepts react-hook-form methods, which will
 * make the component be wrapped in a `FormProvider`.
 * @component
 */
const FormBase = <T,>({
  children = null,
  component = undefined,
  formProps = undefined,
  methods
}: FormBaseProps<T>) => (
  <Container<T> methods={methods}>
    <Box
      component={component || 'form'}
      role="form"
      flex="1"
      display="flex"
      flexDirection="column"
      onSubmit={(e) => e.preventDefault()}
      {...formProps}>
      {children}
    </Box>
  </Container>
)

export default FormBase
