import React from 'react'

import { get as lodashGet } from 'lodash'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import Autocomplete from 'components/common/inputs/Autocomplete'
import type { FormAutocompleteProps } from 'components/forms/FormAutocomplete/FormAutocomplete.types'

import type { ValueLabelPair } from '@repo/et-types'

const FormAutocomplete = ({
  name,
  required,
  values,
  defaultValue,
  onChange,
  ...props
}: FormAutocompleteProps) => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const getDefaultValue = () =>
    typeof defaultValue === 'string' ? defaultValue : (defaultValue as ValueLabelPair)?.value

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={getDefaultValue()}
      rules={{ required }}
      render={({ field: { onChange: onValueChange, onBlur, value, ref } }) => (
        <Autocomplete
          isError={isError}
          defaultValue={defaultValue}
          errorMessage={errorMessage}
          required={required}
          name={name}
          values={values}
          onChange={(newValue) => {
            onValueChange(newValue)
            onChange?.(newValue)
          }}
          inputRef={ref}
          onBlur={onBlur}
          value={value}
          {...props}
        />
      )}
    />
  )
}

export default FormAutocomplete
