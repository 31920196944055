import React from 'react'

import { Box, Chip, Typography } from '@mui/material'

import type { ReadOnlyProps } from 'components/common/inputs/ReadOnly'

const ReadOnly = ({ children, className, label, value, TypographyProps = {} }: ReadOnlyProps) => {
  const renderValue = () => {
    if (Array.isArray(value) && value.length > 0) {
      return value.map((val, index) => {
        if (typeof val === 'string') {
          return <Chip key={index} label={val} sx={{ marginRight: '.25rem' }} />
        }

        return val
      })
    }

    const displayValue = value || (!children && 'None')

    return (
      <Typography
        {...TypographyProps}
        color={displayValue === 'None' ? 'text.secondary' : TypographyProps.color}
        sx={{ wordBreak: 'break-word' }}>
        {displayValue}
      </Typography>
    )
  }

  return (
    <Box className={className} display="flex" gap={2}>
      <Typography variant="body2" color="text.secondary" sx={{ width: '120px', flexShrink: 0 }}>
        {label}
      </Typography>
      <Box display="flex" flexWrap="wrap">
        {children}
        {renderValue()}
      </Box>
    </Box>
  )
}

export default ReadOnly
