import React from 'react'

import { get as lodashGet } from 'lodash'
import { Controller, useFormContext, useFormState } from 'react-hook-form'

import TimePicker from 'components/common/date/TimePicker'
import type { FormTimePickerProps } from 'components/forms/FormTimePicker/FormTimePicker.types'

const FormTimePicker = ({ name, required, slotProps, ...props }: FormTimePickerProps) => {
  const { control } = useFormContext()
  const { errors, defaultValues } = useFormState({ control, name })

  let isError = false
  let errorMessage = ''

  if (errors && lodashGet(errors, name)) {
    isError = true
    errorMessage = lodashGet(errors, name)?.message as string
  }

  const defaultValue = lodashGet(defaultValues, name)

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <TimePicker
          {...props}
          inputRef={ref}
          name={name}
          error={isError}
          errorMessage={errorMessage}
          defaultValue={defaultValue}
          onChange={onChange}
          slotProps={{ ...slotProps, textField: { onBlur, ...slotProps?.textField } }}
          value={value}
        />
      )}
    />
  )
}

export default FormTimePicker
